<template>
  <div :class="formGroupClass">
    <label v-if="displayLabel" :for="fieldName">{{ fieldName }}</label>
    <textarea
      v-if="textAreaInput"
      :class="inputClass"
      :id="fieldName"
      :aria-label="ariaDescription"
      :placeholder="placeholderText"
      :disabled="disabled"
      v-model="input"
    />
    <input
      v-else
      type="text"
      :class="inputClass"
      :id="fieldName"
      :aria-label="ariaDescription"
      :placeholder="placeholderText"
      :disabled="disabled"
      v-model="input"
    />
    <small v-if="fieldDescription" class="form-text text-muted">{{
      fieldDescription
    }}</small>
  </div>
</template>

<script>
export default {
  name: "TextField",
  props: {
    fieldName: {
      type: String,
      required: true, // required for aria accessibility
    },
    placeholderText: String,
    fieldDescription: String,
    displayLabel: {
      type: Boolean,
      default: true, // in case we don't want to have the required fieldName displayed
    },
    inputClass: {
      type: String,
      default: "form-control form-control-sm", // for changing size
    },
    formGroupClass: {
      type: String,
      default: "form-group text-left small", // for changing field layouts, defaults to vertical stack of label, input, description
    },
    textAreaInput: {
      type: Boolean,
      default: false, // switch between single-line and large area input size
    },
    value: String,
    disabled: Boolean,
  },
  computed: {
    ariaDescription() {
      return this.fieldDescription ? this.fieldDescription : this.fieldName;
    },
    input: {
      get() {
        return this.value;
      },
      set(inputValue) {
        this.$emit("input", inputValue);
      },
    },
  },
};
</script>

<style scoped></style>
