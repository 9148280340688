import { render, staticRenderFns } from "./NotesField.vue?vue&type=template&id=0abd1249&scoped=true&"
import script from "./NotesField.vue?vue&type=script&lang=js&"
export * from "./NotesField.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0abd1249",
  null
  
)

export default component.exports