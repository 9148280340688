<template>
  <div class="card border-light mb-1">
    <div class="card-body p-2">
      <div class="row">
        <div class="col-2 mb-auto p-2 text-center">
          <i :class="iconSymbol" :style="{ color: iconColor }"></i>
        </div>

        <div class="col mb-auto pt-2">
          <div class="row">
            <div class="col">
              {{ eventName }}
            </div>
          </div>
          <div class="row mt-1 small" v-if="hasLinkedItem">
            <LinkedItem
              class="col"
              :itemType="linkedItemType"
              :itemObject="linkedItemObject"
            />
          </div>
          <div class="row mt-3 small text-muted" v-if="event.user">
            <div class="col">
              <i class="far fa-user mr-1" />
              <b>{{ userFullName }}</b>
            </div>
          </div>
          <div class="row small text-muted" v-if="event.notes">
            <div class="col">
              {{ event.notes }}
            </div>
          </div>
        </div>

        <div class="col col-auto text-right mb-auto">
          <slot name="time">
            <div class="small text-muted">{{ eventTime[0] }}</div>
            <div class="small text-muted">{{ eventTime[1] }}</div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LinkedItem from "@/components/renderingComponents/LinkedItem";
import { retrieveItemByUUID } from "@/common-functions.js";

export default {
  name: "ItemTimelineEvent",
  props: {
    event: Object,
    users: Object,
    allItemTriggers: Object,
    itemType: String,
  },
  computed: {
    userFullName() {
      return this.users[this.event.user].full_name;
    },
    trigger() {
      return this.allItemTriggers[this.event.trigger];
    },
    iconSymbol() {
      return this.trigger.icon.symbol;
    },
    iconColor() {
      return this.trigger.icon.color;
    },
    eventName() {
      return this.trigger.eventName;
    },
    eventTime() {
      return [
        new Date(this.event.datetime).toLocaleDateString("en-gb"),
        new Date(this.event.datetime).toLocaleTimeString("en-gb"),
      ];
    },
    hasLinkedItem() {
      return this.event.rider && this.event.tracker;
    },
    linkedItemType() {
      return this.itemType == "rider" ? "tracker" : "rider";
    },
    linkedItemObject() {
      let uuid =
        this.itemType == "rider" ? this.event.tracker : this.event.rider;
      return retrieveItemByUUID(this.$store, this.linkedItemType, uuid);
    },
  },
  components: {
    LinkedItem,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
