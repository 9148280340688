<template>
  <ApplyFlowLayout :race="selectedRace" :title="applicant.full_name">
    <template v-slot:body>
      <Loading v-if="loading" />
      <GetApplyRace @race-selected="selectRace" @error="redirect404" />
      <template v-if="selectedRace.question_guidance">
        <p>
          {{ selectedRace.question_guidance[applicant.default_language] }}
        </p>
      </template>
      <template v-if="applicant.pair_email">
        <p>
          {{ selectedRace.pair_question_guidance[applicant.default_language] }}
        </p>
      </template>
      <FormBuilder
        :fields="questionFields"
        @submit="submitApply"
        @save="submitSave"
      />
    </template>
  </ApplyFlowLayout>
</template>

<script>
import ApplyFlowLayout from "@/components/layoutComponents/ApplyFlowLayout.vue";
import Loading from "@/components/renderingComponents/Loading.vue";
import GetApplyRace from "@/components/logicComponents/GetApplyRace.vue";
import FormBuilder from "@/components/renderingComponents/FormBuilder.vue";

export default {
  name: "ApplyQuestions",
  components: {
    FormBuilder,
    ApplyFlowLayout,
    GetApplyRace,
    Loading,
  },
  data() {
    return {
      loading: true,
      questionFields: [],
      applicant: Object(),
      selectedRace: Object(),
    };
  },
  mounted() {
    this.getQuestions();
    this.getApplicant();
  },
  methods: {
    redirect404() {
      this.$router.push({ name: "notFound" });
    },
    selectRace(race) {
      this.loading = false;
      this.selectedRace = race;
    },
    getApplicant() {
      this.$store
        .dispatch("applyStore/getApplicantV2", this.$route.params.uuid)
        .then((res) => {
          this.loading = false;
          this.applicant = res;
          if (this.applicant.state == "submitted") {
            this.$router.push({
              name: "applyThankYou",
              params: {
                raceShortName: this.applicant.race_short_name,
              },
              query: {
                language: this.applicant.default_language,
              },
            });
          }
          if (this.applicant.state == "checking_out") {
            window.location.href = this.applicant.checkout_url;
          }
        });
    },
    submitApply(data) {
      this.loading = true;
      this.$store
        .dispatch("applyStore/postApplicantAnswers", {
          applicantUUID: this.$route.params.uuid,
          answer_data: data,
        })
        .then((res) =>
          this.$store
            .dispatch("applyStore/submitApplicant", this.$route.params.uuid)
            .then(
              (res) => (
                (this.loading = false),
                (window.location.href = res.checkout_url)
              )
            )
        );
    },
    submitSave(data) {
      this.loading = true;
      this.$store
        .dispatch("applyStore/postApplicantAnswers", {
          applicantUUID: this.$route.params.uuid,
          answer_data: data,
        })
        .then(
          (res) => (
            (this.loading = false),
            this.$store.dispatch("newMessage", {
              message: "Application Saved, 👍",
              status: res.status,
            })
          )
        );
    },
    getQuestions() {
      (this.loading = true),
        this.$store
          .dispatch("applyStore/getApplicantAnswers", this.$route.params.uuid)
          .then((res) => (this.questionFields = res), (this.loading = false));
    },
  },
};
</script>
<style scoped>
textarea.form-control {
  height: 12em !important;
}
</style>
