<template>
  <ApplyFlowLayout :race="selectedRace">
    <template v-slot:body>
      <div class="text-center">
        <GetApplyRace @race-selected="selectRace" @error="redirect404" />
        <p v-if="selectedRace.check_email">
          {{
            $route.query.language
              ? selectedRace.check_email[$route.query.language]
              : selectedRace.check_email["english"]
          }}
        </p>
        <img
          width="250"
          src="@/assets/nodCheckEmail.svg"
          alt="Nod checking email"
        />
      </div>
    </template>
  </ApplyFlowLayout>
</template>

<script>
import ApplyFlowLayout from "@/components/layoutComponents/ApplyFlowLayout.vue";
import GetApplyRace from "@/components/logicComponents/GetApplyRace.vue";

export default {
  name: "ApplyCheckEmail",
  components: {
    ApplyFlowLayout,
    GetApplyRace,
  },
  data() {
    return {
      loading: false,
      selectedRace: Object(),
    };
  },
  mounted() {},
  methods: {
    redirect404() {
      this.$router.push({ name: "notFound" });
    },
    selectRace(race) {
      this.loading = false;
      this.selectedRace = race;
    },
  },
};
</script>
