<template>
  <div>
    <div class="card-body pb-0">
      <h5>Emergency Contact</h5>
      <table class="table table-sm table-hover">
        <tbody>
          <tr v-for="(value, key) in emergencyContact" :key="key">
            <td>{{ key }}</td>
            <td>{{ value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmergencyContact",
  components: {},
  props: {
    emergencyContact: Object,
  },
};
</script>

<style scoped>
.card-body {
  text-align: left;
}

.close-button {
  cursor: pointer;
}
</style>
