import axios from "axios";
import { listToDict } from "../common-functions";

const state = () => ({
  trackers: null,
});

const mutations = {
  setTrackers(state, trackers) {
    state.trackers = trackers;
  },
};

const actions = {
  getTrackerDetail({ rootState }, trackerUUID) {
    let url =
      rootState.baseURL + rootState.apiRoutes.trackers + trackerUUID + "/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          let tracker = resp.data;
          for (let event of tracker.history) {
            event.eventType = "event";
          }
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllTrackers({ commit, rootState }, raceUUID) {
    let url = rootState.baseURL + "api/v1/races/" + raceUUID + "/trackers/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          let data = resp.data;
          let trackers = listToDict(data);
          commit("setTrackers", trackers);
          resolve(trackers);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  submitTrackerTrigger({ rootState }, { trackerUUID, trigger, data }) {
    let url =
      rootState.baseURL +
      rootState.apiRoutes.trackers +
      trackerUUID +
      "/triggers/" +
      trigger +
      "/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "POST",
        data: data,
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default { namespaced: true, state, mutations, actions };
