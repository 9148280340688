<template>
  <div class="dashboard-card card text-left">
    <div class="card-body">
      <h5 class="card-title">
        <slot name="icon">
          <i class="fa fa-biking mr-1" />
        </slot>
        {{ cardTitle }}
      </h5>
      <h6 class="card-subtitle mb-2 text-muted">{{ numberOfItems }}</h6>

      <div class="row">
        <div
          class="col-lg-6"
          v-for="[state, count] of Object.entries(itemStates)"
          :key="state"
        >
          <div class="row mb-1">
            <StateBadge
              class="col"
              :badgeText="referenceStates[state].verboseName"
              :overrideColor="referenceStates[state].color"
            />
            <div class="col col-auto pl-0 pr-2">{{ count }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StateBadge from "@/components/renderingComponents/StateBadge.vue";

export default {
  name: "DashboardCard",
  components: {
    StateBadge,
  },
  props: {
    numberOfItems: Number,
    referenceStates: Object,
    itemStates: Object,
    cardTitle: String,
  },
};
</script>

<style scoped></style>
