<template>
  <Loading v-if="loading" size="100px" />
  <DashboardLayout v-else>
    <template v-slot:title>
      <h1 class="text-left">Applicants</h1>
    </template>

    <template v-slot:body>
      <div>
        <b-form-group>
          <b-form-input
            id="filter-input"
            v-model="filters.userSearch"
            type="search"
            placeholder="Type to Search"
          ></b-form-input>
        </b-form-group>
        <b-form @submit.prevent>
          <b-form-group
            style="max-width: 30em; text-align: left"
            label="Status:"
          >
            <b-form-select
              :options="applicantStates"
              value-field="rawValue"
              text-field="verboseName"
              v-model="filters.state"
              name="select-1"
              class="mb-1"
            >
            </b-form-select>
          </b-form-group>
          <b-form-group
            style="max-width: 30em; text-align: left"
            label="Nationality:"
          >
            <b-form-select
              id="select-nationality"
              :options="applicantNationalities"
              v-model="filters.nationality"
              name="select-2"
              class="mb-1"
            >
            </b-form-select>
          </b-form-group>
          <b-form-group
            style="max-width: 30em; text-align: left"
            label="Category:"
          >
            <b-form-select
              id="select-category"
              :options="applicantCategories"
              v-model="filters.category"
              name="select-2"
              class="mb-1"
            >
            </b-form-select>
          </b-form-group>
          <b-form-group
            style="max-width: 30em; text-align: left"
            label="Gender:"
            v-if="raceFields.map((x) => x.key).includes('gender')"
          >
            <b-form-select
              :options="applicantGenders"
              v-model="filters.gender"
              name="select-2"
              class="mb-1"
            >
            </b-form-select>
          </b-form-group>

          <b-form-group class="text-left">
            <b-button @click="onReset" variant="danger" type="submit"
              >Clear Filter</b-button
            >
          </b-form-group>
        </b-form>
      </div>
      <h3 class="my-2" style="text-align: left">Total: {{ countRows }}</h3>
      <b-container fluid class="text-light text-center">
        <b-row>
          <b-col cols="auto" class="mr-auto p-3">
            <b-button
              class="mb-3"
              style="text-align: left"
              @click="exportApplicants"
              variant="primary"
              >Export All</b-button
            >
          </b-col>
          <b-col cols="auto" class="p-3">
            <b-link
              :href="
                $store.state.defaultProtocol +
                'apply/' +
                $store.state.racesStore.selectedRace.short_name +
                '/create'
              "
              target="blank"
            >
              <b-button
                class="mb-3"
                style="text-align: left"
                v-if="createApplicantAvailable"
                variant="secondary"
                >Create New Applicant</b-button
              ></b-link
            >
          </b-col>
        </b-row>
      </b-container>
      <b-table
        id="my-table"
        striped
        hover
        :filter-function="filterFunction"
        :filter="filters"
        :fields="fields"
        :items="applicants"
        @filtered="onFiltered"
      >
        <template #cell(full_name_pair)="row">
          {{ row.item.full_name }}
          <TagBadge
            class="col col-auto px-1"
            v-if="row.item.pair"
            fa-icon="p"
            color="info"
          />
        </template>
        <template #cell(show_details)="row">
          <b-button size="sm" class="mr-2" @click="toggleDetails(row)">
            Details
          </b-button>
        </template>
        <template #row-details="row">
          <b-card>
            <Loading v-if="rowLoading" size="100px" />
            <template v-else>
              <b-card-title>
                {{ selectedApplicant.full_name }}
                <b-link
                  v-if="selectedApplicant.state == 'created'"
                  :href="
                    $store.state.defaultProtocol +
                    selectedApplicant.application_link
                  "
                  target="blank"
                  ><i class="fa fa-edit mr-1" style="font-size: 0.5em" /></b-link>
                <span v-if="row.item.pair">
                  & {{ secondaryApplicant.full_name }}
                  <b-link
                    v-if="secondaryApplicant.state == 'created'"
                    :href="
                      $store.state.defaultProtocol +
                      secondaryApplicant.application_link
                    "
                    target="blank"
                    ><i class="fa fa-edit mr-1" style="font-size: 0.5em"
                  /></b-link>
                </span>
              </b-card-title>
              <b-card-text>
                <b-card-sub-title>
                  <span
                    v-for="attribute in applicantAttributes"
                    :key="attribute"
                  >
                    <strong>{{ attribute }}:</strong>
                    {{ selectedApplicant[attribute] }}
                  </span>
                </b-card-sub-title>
              </b-card-text>
              <b-card-text v-if="row.item.pair">
                <b-card-sub-title>
                  <span
                    v-for="attribute in applicantAttributes"
                    :key="attribute"
                  >
                    <strong>{{ attribute }}:</strong>
                    {{ secondaryApplicant[attribute] }}
                  </span>
                </b-card-sub-title>
              </b-card-text>
            </template>
            <b-card-text>
              <template>
                <span
                  v-for="trigger in selectedApplicant.user_triggers"
                  :key="trigger"
                >
                  <span
                    v-if="
                      applicantTriggers[trigger] &&
                      applicantTriggers[trigger].userTrigger
                    "
                  >
                    <b-button
                      @click="triggerApplicant(trigger, row)"
                      class="m-2"
                      :variant="applicantTriggers[trigger].colorVariant"
                    >
                      {{ applicantTriggers[trigger].verboseName }}
                    </b-button>
                  </span>
                </span>
              </template>
            </b-card-text>
            <b-card-text >
              <h5>Application Answers<i class="fa fa-eye ml-2" style="font-size: 1em; cursor:pointer" @click="toggleApplicationAnswers"/></h5>
	     <div v-if="showApplicationAnswers">
              <b-list-group
                v-for="question in selectedApplicant.answers"
                :key="question.question"
              >
                <strong
                  ><b-list-group-item>
                    {{ question.question }}
                  </b-list-group-item></strong
                >
                <b-list-group-item> {{ question.answer }} </b-list-group-item>
              </b-list-group>
	     </div>
            </b-card-text>
            <b-card-text>
              <h5>Documents</h5>
              <DocumentUploads
                :applicantName="selectedApplicant.full_name"
                :documentUploads="selectedApplicant.document_uploads"
              />

              <DocumentUploads
                v-if="row.item.pair"
                :applicantName="secondaryApplicant.full_name"
                :documentUploads="secondaryApplicant.document_uploads"
              />
            </b-card-text>
            <b-card-text>
              <h5>History<i class="fa fa-eye ml-2" style="font-size: 1em; cursor:pointer" @click="toggleHistory"/></h5><div v-if="showHistory">
              <ApplicantHistory
                :applicantName="selectedApplicant.full_name"
                :history="selectedApplicant.history"
              />
              <ApplicantHistory
                v-if="row.item.pair"
                :applicantName="secondaryApplicant.full_name"
                :history="secondaryApplicant.history"
              /></div>
            </b-card-text>
          </b-card>
        </template>
      </b-table>
    </template>
  </DashboardLayout>
</template>

<script>
import Loading from "@/components/renderingComponents/Loading";
import DocumentUploads from "@/components/renderingComponents/DocumentUploads";
import DashboardLayout from "@/components/layoutComponents/DashboardLayout";
import TagBadge from "@/components/renderingComponents/TagBadge.vue";
import ApplicantHistory from "@/components/renderingComponents/ApplicantHistory.vue";
import { applicantData } from "@/reference/ApplicantReferenceData.js";
import { fileDownload } from "@/common-functions.js";

export default {
  name: "ApplicationsPage",
  components: {
    DashboardLayout,
    Loading,
    TagBadge,
    DocumentUploads,
    ApplicantHistory,
  },
  data() {
    return {
      applicants: [],
      selectedApplicant: null,
      secondaryApplicant: null,
      showApplicationAnswers: false,
      showHistory: false,
      applicantAttributes: [],
      applicantCategories: [],
      applicantNationalities: [],
      applicantGenders: [],
      filters: { userSearch: "" },
      rowLoading: false,
      loading: false,
      raceFields: [],
      alwaysFields: [
        { key: "full_name_pair", label: "Name" },
        { key: "state", label: "Status" },
      ],
      countRows: 0,
    };
  },
  computed: {
    fields() {
      return this.alwaysFields
        .concat(this.raceFields)
        .concat([{ key: "show_details", label: "" }]);
    },
    applicantStates() {
      return applicantData.applicantStates;
    },
    applicantTriggers() {
      return applicantData.applicantTriggers;
    },
    combinedFilter() {
      return this.userFilter;
    },
  },
  mounted() {
    this.$store
      .dispatch(
        "applyStore/getRaceAdmin",
        this.$store.state.racesStore.selectedRace.uuid
      )
      .then(
        (res) => (
          (this.raceFields = res.list_fields),
          (this.applicantAttributes = res.detail_fields),
          (this.createApplicantAvailable = res.create_applicant_available
            ? res.create_applicant_available
            : false)
        )
      );
    this.$store
      .dispatch(
        "applyStore/getAllApplicants",
        this.$store.state.racesStore.selectedRace.uuid
      )
      .then(
        (res) => (
          (this.applicants = res),
          (this.countRows = res.length),
          (this.applicantNationalities = [
            ...new Set(res.map((applicant) => applicant.nationality)),
          ]),
          (this.applicantGenders = [
            ...new Set(res.map((applicant) => applicant.gender)),
          ]),
          (this.applicantCategories = [
            ...new Set(res.map((applicant) => applicant.category)),
          ])
        )
      );
  },
  methods: {
    toggleApplicationAnswers() {
	this.showApplicationAnswers = !this.showApplicationAnswers
	},
    toggleHistory() {
	this.showHistory = !this.showHistory
},
    exportApplicants() {
      this.loading = true;
      const daate = new Date().toISOString().slice(0, 10);
      this.$store
        .dispatch(
          "applyStore/exportApplicants",
          this.$store.state.racesStore.selectedRace.uuid
        )
        .then((res) => {
          fileDownload(
            res,
            `${this.$store.state.racesStore.selectedRace.short_name}-applicants-${daate}.csv`
          );
          this.loading = false;
        });
    },
    onReset() {
      this.filters = { userSearch: "" };
    },
    filterFunction(row, userFilter) {
      let allowRow = true;
      Object.entries(userFilter).forEach(([key, value]) => {
        if (!(key == "userSearch") && value) {
          if (row[key] != value) {
            allowRow = false;
          }
        }
      });
      if (userFilter.userSearch && allowRow) {
        let match = Object.values(row).filter((x) =>
          JSON.stringify(x)
            .toLowerCase()
            .includes(userFilter.userSearch.toLowerCase())
        );
        allowRow = match.length > 0 ? true : false;
      }
      return allowRow;
    },
    triggerApplicant(trigger, row) {
      this.rowLoading = true;
      if (row.item.pair) {
        if (this.selectedApplicant.state != this.secondaryApplicant.state) {
          this.$store.dispatch("newMessage", {
            message:
              "Each rider in the pair is not in the same state, so unable to perform this action.",
          });
          row.toggleDetails(), (this.rowLoading = false);
          return;
        }
        this.$store
          .dispatch("applyStore/getPair", row.item.pair)
          .then((res) => {
            this.rowLoading = true;
            Promise.all([
              this.$store.dispatch("applyStore/submitApplicantTrigger", {
                applicantUUID: res.lead,
                trigger: trigger,
              }),
              this.$store.dispatch("applyStore/submitApplicantTrigger", {
                applicantUUID: res.secondary,
                trigger: trigger,
              }),
            ]).then((results) => {
              (row.item.state = results[0].data.state),
                row.toggleDetails(),
                (this.rowLoading = false);
            });
          });
      } else {
        this.rowLoading = true;
        this.$store
          .dispatch("applyStore/submitApplicantTrigger", {
            applicantUUID: row.item.uuid,
            trigger: trigger,
          })
          .then(
            (res) => (
              (row.item.state = res.data.state),
              row.toggleDetails(),
              (this.rowLoading = false)
            )
          );
      }
    },
    onFiltered(filteredItems) {
      this.countRows = filteredItems.length;
    },
    toggleDetails(row) {
      this.rowLoading = true;
      this.showApplicationAnswers = false;
      this.showHistory = false;
      if (row.item._showDetails) {
        row.item._showDetails = false;
        return;
      }
      this.applicants.forEach((item) => {
        this.$set(item, "_showDetails", false);
      });
      this.selectedApplicant = {};
      if (row.item.pair) {
        this.secondaryApplicant = {};
        this.$store
          .dispatch("applyStore/getPair", row.item.pair)
          .then((res) => {
            this.$store
              .dispatch("applyStore/getApplicant", res.lead)
              .then((res) => {
                this.selectedApplicant = res;
                this.rowLoading = false;
              });
            this.$store
              .dispatch("applyStore/getApplicant", res.secondary)
              .then((res) => {
                this.secondaryApplicant = res;
                this.rowLoading = false;
              });
          });
      } else {
        this.$store
          .dispatch("applyStore/getApplicant", row.item.uuid)
          .then((res) => {
            row.item.state = res.state;
            this.selectedApplicant = res;
          });
      }
      row.toggleDetails();
      this.rowLoading = false;
    },
  },
};
</script>
