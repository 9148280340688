<template>
  <div class="responsive-panels-layout">
    <div class="row">
      <div class="col panel-left pb-2">
        <div class="sticky-helper">
          <slot name="leftPanelSticky"></slot>
        </div>
        <div class="scroll-fixer">
          <div class="scroll-helper">
            <slot name="leftPanelScroll"></slot>
          </div>
        </div>
      </div>

      <div class="col panel-right">
        <slot name="rightPanel"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResponsivePanelsLayout",
};
</script>

<style scoped>
.sticky-helper {
  z-index: 20;
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
  padding-top: 30px;
}

.scroll-fixer {
  top: 140px;
  position: -webkit-sticky;
  position: sticky;
}

.scroll-helper {
  max-height: 75vh;
  overflow: auto;
}

@media (max-width: 600px) {
  .scroll-helper {
    max-height: 50vh;
  }
}

.panel-left {
  z-index: 10;
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
  background-color: white;
  min-width: 300px;
  box-shadow: 0px 15px 10px -15px rgb(127, 127, 127);
}

.panel-right {
  position: relative;
  z-index: 1;
  min-width: 300px;
  min-height: 3000px;
  margin-top: 30px;
}
</style>
