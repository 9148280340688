<template>
  <div class="RBtooltip">
    <slot></slot>
    <span class="RBtooltiptext">{{ toolTipText }}</span>
  </div>
</template>

<script>
export default {
  name: "ToolTip",
  props: {
    toolTipText: {
      type: String,
    },
  },
  computed: {},
};
</script>

<style scoped>
.RBtooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.RBtooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.RBtooltip:hover .RBtooltiptext {
  visibility: visible;
}
</style>
