<template>
  <form>
    <div class="form-group">
      <select
        v-model="selectedControlPoint"
        class="form-control"
        id="exampleFormControlSelect1"
      >
        <option
          v-for="option in options"
          :key="option.abbreviation"
          :value="option"
        >
          {{ option.abbreviation }}
        </option>
      </select>
    </div>
  </form>
</template>

<script>
export default {
  name: "SelectControlPoint",
  props: {
    defaultSelection: {
      type: Object,
    },
    options: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  computed: {
    selectedControlPoint: {
      get() {
        return this.defaultSelection;
      },
      set(value) {
        this.$emit("select-control-point", value);
      },
    },
  },
};
</script>

<style scoped></style>
