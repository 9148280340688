<template>
  <div>
    <AdvancedArrivalOptions
      @newTime="newTime"
      :showEditDefault="isArrivalUpdate"
      :arrivalTime="arrivalTime"
      :timeZone="this.controlPoint.timezone"
    />
    <ArrivalForm
      :rider="rider"
      :controlPoint="controlPoint"
      :formTitle="formTitle"
      :loading="loading"
      :isDisabled="isDisabled"
      :arrivalTime="arrivalTime"
      @form-submission="emitForm"
    />
  </div>
</template>

<script>
import ArrivalForm from "@/components/forms/ArrivalForm";
import moment from "moment-timezone";
import AdvancedArrivalOptions from "@/components/logicComponents/AdvancedArrivalOptions";
export default {
  name: "RegisterArrival",
  components: {
    ArrivalForm,
    AdvancedArrivalOptions,
  },
  props: {
    rider: Object,
    controlPoint: Object,
    loading: Boolean,
  },
  data() {
    return {
      arrivalTime: null,
      timeFormOpened: new moment().tz(this.controlPoint.timezone),
      timeAdjustment: 0,
    };
  },
  computed: {
    isArrivalUpdate() {
      return this.rider.arrival ? true : false;
    },
    isDisabled() {
      if (
        this.isArrivalUpdate &&
        this.arrivalTime -
          new moment(this.rider.arrival.arrival_time).tz(
            this.controlPoint.timezone
          ) ==
          0
      ) {
        return true;
      }
      return false;
    },
    formTitle() {
      return this.isArrivalUpdate
        ? "Edit arrival time"
        : "Register new arrival";
    },
  },
  watch: {
    rider() {
      if (this.rider.arrival) {
        this.arrivalTime = new moment(this.rider.arrival.arrival_time).tz(
          this.controlPoint.timezone
        );
      }
    },
  },
  methods: {
    emitForm(formData) {
      if (this.rider.arrival) {
        formData.uuid = this.rider.arrival.uuid;
        formData.arrivalTime = this.arrivalTime;
        this.$emit("update-arrival", formData);
      } else {
        this.$emit("submit-arrival", formData);
      }
    },
    newTime(time) {
      this.arrivalTime = time;
    },
  },
  created() {
    if (this.rider.arrival) {
      this.arrivalTime = new moment(this.rider.arrival.arrival_time).tz(
        this.controlPoint.timezone
      );
    }
  },
};
</script>

<style scoped></style>
