<template>
  <div class="card-body small">
    <a
      class="edit-time-text"
      href=""
      @click="openTimePicker"
      v-if="!showTimePicker"
      onclick="return false;"
    >
      <i class="fa fa-edit text-primary mr-1"></i>
      Edit arrival time
    </a>
    <div v-if="showTimePicker" class="mt-2">
      <datetime
        v-model="newTime"
        :zone="this.timeZone"
        class="form-control form-control-sm"
        type="datetime"
      ></datetime>
      Edits must be made in <strong>CEST</strong>.
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
import moment from "moment-timezone";
import "vue-datetime/dist/vue-datetime.css";
export default {
  name: "AdvancedArrivalOptions",
  components: {
    datetime: Datetime,
  },
  props: {
    timeZone: String,
    arrivalTime: [Object, null],
  },
  data() {
    return {
      showTimePicker: false,
    };
  },
  computed: {
    newTime: {
      get() {
        if (this.arrivalTime) {
          return new moment(this.arrivalTime).tz(this.timeZone).toISOString();
        } else {
          return new moment(this.arrivalTime).tz(this.timeZone).toISOString();
        }
      },
      set(value) {
        let new_time = moment(value).tz(this.timeZone);
        this.$emit("newTime", new_time);
      },
    },
  },
  methods: {
    openTimePicker() {
      this.showTimePicker = true;
    },
    toggleEdit() {
      this.showEdit = !this.showEdit;
    },
  },
};
</script>

<style scoped>
.edit-time-text {
  text-decoration: none;
}
</style>
