<template>
  <TextField
    :fieldName="fieldName"
    :fieldDescription="fieldDescription"
    :value="value"
    :displayLabel="displayLabel"
    :disabled="true"
  />
</template>

<script>
import TextField from "@/components/formFieldComponents/TextField.vue";

export default {
  name: "ReadOnlyField",
  props: {
    fieldName: {
      type: String,
      required: true, // required for aria accessibility
    },
    fieldDescription: String,
    displayLabel: Boolean,
    value: String,
  },
  components: {
    TextField,
  },
};
</script>

<style scoped></style>
