<template>
  <b-container>
    <form class="form" @submit.prevent="changePassword">
      <b-row class="justify-content-md-center">
        <b-col cols="6">
          <b-card title="Change Password" title-tag="h1">
            <b-form-input
              type="password"
              class="mb-3"
              placeholder="Password"
              v-model="newPassword"
            />
            <b-form-input
              type="password"
              class="mb-3"
              placeholder="Confirm Password"
              v-model="newPasswordConfirmation"
            />
            <template #footer>
              <b-button variant="secondary" @click="changePassword">{{
                loading ? "..." : "Submit"
              }}</b-button>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
import SubmitButton from "@/components/formFieldComponents/SubmitButton.vue";

export default {
  components: {
    SubmitButton,
  },
  name: "ChangePassword",
  data() {
    return {
      loading: false,
      newPassword: "",
      newPasswordConfirmation: "",
    };
  },
  methods: {
    changePassword() {
      if (this.newPassword != this.newPasswordConfirmation) {
        this.$store.dispatch("newMessage", {
          message: "Password doesn't match confirmation, please try again",
        });
      } else {
        this.$store
          .dispatch("usersStore/changePassword", this.newPassword)
          .then((response) => {
            console.log(response);
            this.$store.dispatch("newMessage", {
              status: response.status,
              message: "Password Succesfully Changed",
            });
            this.newPassword = "";
            this.newPasswordConfirmation = "";
          });
      }
    },
  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 30px;
}
</style>
