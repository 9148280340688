<template>
  <div class="listOfCards">
    <div v-if="cards.length == 0" class="card bg-light">
      <div class="card-body py-3">
        <div class="text-muted">
          <i>{{ emptyStateText }}</i>
        </div>
      </div>
    </div>

    <div
      v-for="card in cards"
      :key="card.uuid"
      class="card"
      @click="$emit('select-detail', card)"
    >
      <div class="card-body py-3 row">
        <div class="col">{{ card[headerField] }}</div>
        <slot name="header-badges" :card="card"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "List",
  props: {
    cards: {
      type: Array,
    },
    headerField: {
      type: String,
    },
    emptyStateText: {
      type: String,
      default: "No items matching search terms",
    },
  },
};
</script>

<style scoped>
.card {
  margin-bottom: 4px;
  text-align: left;
  cursor: pointer;
}
.listOfCards {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
</style>
