<template>
  <div class="card-body bg-light">
    <h5 class="text-left">
      {{ formTitle }}
    </h5>

    <form class="form">
      <ReadOnlyField
        :value="rider.fullName"
        fieldName="Rider"
        :displayLabel="true"
      />
      <ReadOnlyField
        :value="tracker.tcr_id"
        fieldName="Tracker"
        :displayLabel="true"
      />
      <PercentField
        fieldName="Percentage to Return"
        :min="0"
        :max="100"
        :step="5"
        @input="setPercent"
      />
      <NotesField v-model="notes" />
      <SubmitButton
        :buttonText="formTitle"
        :loading="loading"
        @submit="emitForm"
      />
    </form>
  </div>
</template>

<script>
import NotesField from "@/components/formFieldComponents/NotesField.vue";
import ReadOnlyField from "@/components/formFieldComponents/ReadOnlyField.vue";
import PercentField from "@/components/formFieldComponents/PercentField.vue";
import SubmitButton from "@/components/formFieldComponents/SubmitButton.vue";

export default {
  name: "RetrieveTracker",
  props: {
    formTitle: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    item: Object,
    itemType: String,
    displayFieldName: String,
  },
  data() {
    return {
      notes: "",
      deposit_to_keep: 100,
    };
  },
  computed: {
    rider() {
      let rider =
        this.itemType == "Rider" ? this.item : this.item.current_rider;
      if (!rider.fullName) {
        rider.fullName = rider.first_name + " " + rider.last_name;
      }
      return rider;
    },
    tracker() {
      return this.itemType == "Tracker" ? this.item : this.item.current_tracker;
    },
  },
  methods: {
    setPercent(percent) {
      this.deposit_to_keep = percent;
    },
    emitForm() {
      if (this.itemType == "Tracker") {
        this.$emit("form-submission", {
          notes: this.notes,
          percent_deposit_rider_keeps: this.deposit_to_keep,
        });
      } else if (this.itemType == "Rider") {
        this.$emit("tracker-form-submission", {
          tracker: this.tracker.uuid,
          data: {
            notes: this.notes,
            percent_deposit_rider_keeps: this.deposit_to_keep,
          },
        });
      }
    },
  },
  components: {
    NotesField,
    ReadOnlyField,
    SubmitButton,
    PercentField,
  },
};
</script>

<style scoped></style>
