<template>
  <div class="card-header">
    <div class="row">
      <div class="col">
        {{ cardHeader }}
      </div>
      <div class="col col-auto close-button" @click="$emit('clear')">
        <i class="fa fa-times" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectedItemHeader",
  props: {
    cardHeader: String,
  },
};
</script>

<style scoped>
.card-header {
  position: -webkit-sticky;
  position: sticky;
}

.close-button {
  cursor: pointer;
}
</style>
