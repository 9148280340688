import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router/index.js";
import createPersistedState from "vuex-persistedstate";
import ridersStore from "./RidersStore";
import trackersStore from "./TrackersStore";
import arrivalsStore from "./ArrivalsStore";
import usersStore from "./UsersStore";
import racesStore from "./RacesStore";
import applyStore from "./ApplyStore";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: false,
  plugins: [
    createPersistedState({
      paths: [
        // Ensure you set all of these paths to null on logout
        "arrivalsStore.selectedControlPoint",
        "usersStore.me",
        "racesStore.selectedRace",
        "racesStore.availableRaces",
      ],
    }),
  ],
  modules: {
    arrivalsStore,
    ridersStore,
    trackersStore,
    usersStore,
    racesStore,
    applyStore,
  },
  state: {
    auth: {
      loading: null,
      access_token: "" || localStorage.getItem("access"),
      refresh_token: "" || localStorage.getItem("refresh"),
      logged_in: !!localStorage.getItem("access"),
    },
    baseURL: "",
    defaultProtocol: "",
    apiRoutes: {
      token: "token/",
      refresh: "token/refresh/",
      forgot_password: "accounts/forgot_password",
      arrivals: "api/v1/arrivals/",
      applicants: "api/v1/applicants/",
      apply: "api/v1/apply/",
      pairs: "api/v1/pairs/",
      riderDetail: "api/v1/riders/",
      races: "api/v1/races/",
      controlPoints: "api/v1/controlpoints/",
      trackers: "api/v1/trackers/",
      organisation: "api/v1/organisations/",
      me: "api/v1/users/me/",
      changePassword: "api/v1/users/change_password/",
    },
    messages: [],
    messageID: 0,
  },
  mutations: {
    set_access(state, access) {
      (state.auth.access_token = access),
        (axios.defaults.headers.common["Authorization"] = "Bearer " + access);
      localStorage.setItem("access", access);
      state.auth.loading = 2;
      state.auth.logged_in = true;
    },
    set_refresh(state, refresh) {
      state.auth.refresh_token = refresh;
      localStorage.setItem("refresh", refresh);
    },
    auth_logout(state) {
      state.auth.access_token = "";
      state.auth.refresh_token = "";
      state.auth.logged_in = false;
      delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      router.push("/login").catch(() => {});
    },
    setMessage(state, payload) {
      state.messageID += 1;
      state.messages.push({
        status: payload.status,
        message: payload.message,
        id: state.messageID,
      });
    },
    killMessage(state) {
      state.messages.shift();
    },
    baseURL(state, url) {
      state.baseURL = url;
    },
    defaultProtocol(state, protocol) {
      state.defaultProtocol = protocol;
    },
  },
  actions: {
    newMessage({ commit }, payload) {
      commit("setMessage", payload);
      setTimeout(() => {
        commit("killMessage");
      }, 3000);
    },
    setBaseURL({ commit }) {
      return new Promise((resolve) => {
        if (process.env.NODE_ENV == "development") {
          commit("baseURL", "http://127.0.0.1:8000/");
          commit("defaultProtocol", "http://");
        } else {
          commit("baseURL", process.env.VUE_APP_BASE_API_URL);
        }
        resolve();
      });
    },
    auth_refresh({ commit, dispatch }) {
      if (this.state.auth.logged_in && this.state.auth.loading != 1) {
        return new Promise((resolve, reject) => {
          axios({
            url: this.state.baseURL + this.state.apiRoutes.refresh,
            data: {
              refresh: this.state.auth.refresh_token,
            },
            method: "POST",
          })
            .then((resp) => {
              commit("set_access", resp.data.access);
              resolve(resp.data.access);
            })
            .catch((err) => {
              commit("authError");
              dispatch("logout");
              reject(err);
            });
        });
      } else if (this.state.auth.loading == 1) {
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    },
    logout({ commit }) {
      commit("usersStore/setMe", null);
      commit("arrivalsStore/setControlPoint", null);
      commit("racesStore/setSelectedRace", null);
      commit("racesStore/setAvailableRaces", null);
      commit("auth_logout");
    },
    login({ rootState, commit, dispatch }, user) {
      return new Promise((resolve) => {
        axios({
          url: this.state.baseURL + this.state.apiRoutes.token,
          data: user,
          method: "POST",
        })
          .then((resp) => {
            commit("set_access", resp.data.access);
            commit("set_refresh", resp.data.refresh);
            dispatch("usersStore/getMe").then((user) => {
              dispatch("usersStore/getAllUsers", user.organisation).then(
                (users) => {
                  commit("usersStore/setUsers", users);
                }
              );
              commit("usersStore/setMe", user);
              commit("usersStore/setAvailableModules", user.available_modules);
              commit("racesStore/setAvailableRaces", user.races);
              if (!rootState.racesStore.selectedRace) {
                commit("racesStore/setSelectedRace", user.races[0]);
              }
            });
            resolve(resp);
          })
          .catch((err) => {
            console.error(err);
          });
      });
    },
  },
});
