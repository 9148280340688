<template>
  <span>
    <b-card-text v-if="applicantName">
      {{ applicantName }} History
    </b-card-text>
    <b-card-text class="d-flex justify-content-center">
      <b-table
        :items="history"
        :fields="fields"
        small
        thead-class="d-none"
        style="max-width: 400px"
      >
        <template #cell(datetime)="data">
          {{ getDateTime(data.item.datetime) }}
        </template>
        <template #cell(event_type)="data">
          <i :class="'fa ' + getEventIcon(data.item.event_type)"></i>
        </template>
        <template #cell(detail)="data">
          <span v-html="getDetail(data.item)"></span>
        </template>
      </b-table>
    </b-card-text>
  </span>
</template>

<script>
import moment from "moment-timezone";
export default {
  name: "ApplicantHistory",
  components: {},
  props: {
    applicantName: { type: String },
    history: { type: Array },
  },
  data() {
    return {
      fields: [
        { key: "event_type", label: "" },
        { key: "datetime", label: "" },
        { key: "detail", label: "" },
      ],
    };
  },
  computed: {},
  methods: {
    getEventIcon(event_type) {
      switch (event_type) {
        case "email":
          return "fa-envelope";
        case "state_change":
          return "fa-arrows-h";
        case "applicant_document":
          return "fa-file";
      }
    },
    getDetail(item) {
      switch (item.event_type) {
        case "email":
          return item.detail.subject;
        case "state_change":
          return item.detail.trigger;
        case "applicant_document":
          return (
            item.detail.document + "<br><em>" + item.detail.trigger + "</em>"
          );
      }
    },
    getDateTime(datetime) {
      return moment(datetime).fromNow();
    },
  },
};
</script>

<style scoped></style>
