<template>
  <div class="card-body bg-light">
    <h5 class="text-left">
      {{ formTitle }}
    </h5>

    <form class="form">
      <ReadOnlyField
        :value="rider.searchString"
        fieldName="Rider"
        :displayLabel="true"
      />
      <ReadOnlyField
        :value="controlPoint.abbreviation"
        fieldName="Control point"
        :displayLabel="true"
      />
      <NotesField v-model="notes" />
      <div class="row form-group">
        <div class="col col-auto">
          <SubmitButton
            :loading="loading"
            @submit="emitForm"
            :isDisabled="isDisabled"
          />
        </div>
        <div v-if="isDisabled" class="col small my-auto">
          <i>Edit arrival time to submit</i>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import NotesField from "@/components/formFieldComponents/NotesField.vue";
import ReadOnlyField from "@/components/formFieldComponents/ReadOnlyField.vue";
import SubmitButton from "@/components/formFieldComponents/SubmitButton.vue";

export default {
  name: "ArrivalForm",
  props: {
    formTitle: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rider: Object,
    controlPoint: Object,
    isDisabled: Boolean,
    arrivalTime: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      notes: "",
    };
  },
  computed: {
    arrivalTimeString() {
      return this.arrivalTime.format("ddd MMM DD YYYY HH:mm");
    },
  },
  methods: {
    emitForm() {
      this.$emit("form-submission", {
        rider: this.rider.uuid,
        controlpoint: this.controlPoint.uuid,
        notes: this.notes,
        arrival_time: this.arrivalTime,
      });
    },
  },
  components: {
    NotesField,
    ReadOnlyField,
    SubmitButton,
  },
};
</script>

<style scoped></style>
