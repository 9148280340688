<template>
  <button
    :class="buttonClass"
    type="button"
    @click="submit"
    :disabled="isDisabled"
  >
    <span v-if="loading">...</span>
    <span v-else>{{ buttonText }}</span>
  </button>
</template>

<script>
export default {
  name: "SubmitButton",
  props: {
    buttonText: {
      type: String,
      default: "Submit",
    },
    buttonClass: {
      type: String,
      default: "btn btn-primary btn-sm",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
};
</script>

<style scoped>
.btn {
  border-radius: 100px;
  box-shadow: none;
}
</style>
