<template>
  <div :class="formGroupClass">
    <label v-if="displayLabel" for="percentSpin">{{ fieldName }}</label>
    <b-form-spinbutton
      id="percentSpin"
      v-model.number="input"
      :min="min"
      :max="max"
      :step="step"
    ></b-form-spinbutton>
    <small v-if="fieldDescription" class="form-text text-muted">{{
      fieldDescription
    }}</small>
  </div>
</template>

<script>
export default {
  name: "PercentField",
  data() {
    return {
      value: 100,
    };
  },
  props: {
    min: { type: Number },
    max: { type: Number },
    step: { type: Number },
    fieldName: {
      type: String,
      required: true, // required for aria accessibility
    },
    fieldDescription: String,
    displayLabel: {
      type: Boolean,
      default: true, // in case we don't want to have the required fieldName displayed
    },
    formGroupClass: {
      type: String,
      default: "form-group text-left small", // for changing field layouts, defaults to vertical stack of label, input, description
    },
    disabled: Boolean,
  },
  computed: {
    ariaDescription() {
      return this.fieldDescription ? this.fieldDescription : this.fieldName;
    },
    input: {
      get() {
        return this.value;
      },
      set(inputValue) {
        this.$emit("input", inputValue);
      },
    },
  },
};
</script>

<style scoped></style>
