<template>
  <ApplyFlowLayout :race="selectedRace">
    <template v-slot:body>
      <GetApplyRace @race-selected="selectRace" @error="redirect404" />
      <span v-if="selectedRace.check_email">{{
        $route.query.language
          ? selectedRace.thank_you[$route.query.language]
          : selectedRace.thank_you["english"]
      }}</span>
      <img src="@/assets/nodThankYou.svg" alt="Nod Thanking You" />
    </template>
  </ApplyFlowLayout>
</template>

<script>
import ApplyFlowLayout from "@/components/layoutComponents/ApplyFlowLayout.vue";
import GetApplyRace from "@/components/logicComponents/GetApplyRace.vue";

export default {
  name: "ApplyThankYou",
  components: {
    ApplyFlowLayout,
    GetApplyRace,
  },
  data() {
    return {
      loading: false,
      selectedRace: Object(),
    };
  },
  mounted() {},
  methods: {
    redirect404() {
      this.$router.push({ name: "notFound" });
    },
    selectRace(race) {
      this.loading = false;
      this.selectedRace = race;
    },
  },
};
</script>

<style scoped></style>
