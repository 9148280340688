<template></template>

<script>
export default {
  name: "GetApplyRace",
  components: {},
  props: {},
  data() {
    return {};
  },
  mounted() {
    console.log("hello");
    this.emitRace();
  },
  watch: {},
  methods: {
    // it is likely that this will 404, so need to gracefully handle this.
    emitRace() {
      this.$store
        .dispatch("applyStore/getRace", {
          raceShortName: this.$route.params.raceShortName,
          token: this.$route.query.token,
        })
        .then((res) => {
          this.$emit("race-selected", res);
        })
        .catch(() => {
          this.$emit("error");
        });
    },
  },
  created() {},
};
</script>
