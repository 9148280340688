<template>
  <form>
    <div class="form-group">
      <select v-model="selectedRace" class="form-control">
        <option v-for="race in races" :key="race.uuid" :value="race">
          {{ race.short_name }}
        </option>
      </select>
    </div>
  </form>
</template>

<script>
export default {
  name: "SelectRace",
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
    races: {
      type: Array,
    },
  },
  data() {
    return {
      defaultRace: null,
    };
  },
  computed: {
    selectedRace: {
      get() {
        return this.$store.state.racesStore.selectedRace;
      },
      set(value) {
        this.$emit("select-race", value);
      },
    },
  },
};
</script>
