import axios from "axios";
import { listToDict } from "../common-functions";
import { moduleData } from "@/reference/ModuleReferenceData.js";

const state = () => ({
  users: [],
  me: null,
});

const setAvailableModules = (available_modules) => {
  if (available_modules[0] == "*") {
    return Object.values(moduleData.modules);
  } else {
    let modules = [];
    available_modules.forEach((x) => modules.push(moduleData.modules[x]));
    console.log(available_modules);
    console.log(modules);
    return modules;
  }
};

const mutations = {
  addUser(state, user) {
    state.users.push(user);
  },
  setUsers(state, users) {
    state.users = users;
  },
  setMe(state, user) {
    state.me = user;
  },
  setAvailableModules(state, available_modules) {
    state.availableModules = setAvailableModules(available_modules);
  },
};

const actions = {
  getMe({ rootState }) {
    let url = rootState.baseURL + rootState.apiRoutes.me;
    return new Promise((resolve, reject) => {
      // this isn't data that will change reguarly, so you only fetch it if it isn't already there.
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllUsers({ rootState }, organisationUUID) {
    let url =
      rootState.baseURL +
      rootState.apiRoutes.organisation +
      organisationUUID +
      "/users/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  changePassword({ rootState }, newPassword) {
    let url = rootState.baseURL + rootState.apiRoutes.changePassword;
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "POST",
        data: { new_password: newPassword },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createUser({ rootState }, userData) {
    let url =
      rootState.baseURL +
      rootState.apiRoutes.organisation +
      rootState.usersStore.me.organisation +
      "/users/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "POST",
        data: userData,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default { namespaced: true, actions, state, mutations };
