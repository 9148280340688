const state = {
  selectedRace: null,
  availableRaces: null,
};

const mutations = {
  setSelectedRace(state, race) {
    state.selectedRace = race;
  },
  setAvailableRaces(state, races) {
    state.availableRaces = races;
  },
};

const actions = {};

export default { namespaced: true, actions, state, mutations };
