<template>
  <div class="card">
    <SelectedItemHeader
      :cardHeader="tracker.tcr_id"
      @clear="$emit('clearTracker')"
    />

    <ItemActions
      :itemTriggers="tracker.triggers"
      :allTriggers="allTrackerTriggers"
      @select-trigger="selectTrigger"
      :selectedTrigger="selectedTrigger"
    />

    <!-- using a dynamic component here, allows us to pass in a different component if more data is needed to be sent -->
    <component
      @form-submission="submitTrigger"
      v-if="selectedTrigger"
      :is="componentMap[selectedTrigger.formComponent].component"
      v-bind="componentMap[selectedTrigger.formComponent].props"
      :loading="formSubmitting"
      :formTitle="selectedTrigger.verboseName"
      :item="tracker"
      itemType="Tracker"
      displayFieldName="tcr_id"
    />

    <div class="card-body">
      <div class="row">
        <StateBadge
          class="col col-auto mb-1"
          :states="trackerStates"
          :stateKey="tracker.state"
        />
        <LinkedItem
          v-if="tracker.current_rider"
          class="col col-auto"
          itemType="rider"
          :itemObject="tracker.current_rider"
        />
        <div v-else-if="tracker.pre_assigned_rider" class="col col-auto row">
          <LinkedItem
            class="col col-auto"
            itemType="rider"
            :itemObject="preassignedRider"
          />
          <TagBadge
            class="col col-auto"
            badgeText="Pre-assigned"
            color="info"
          />
        </div>
        <div v-else class="col col-auto text-muted small my-auto">No rider</div>
      </div>
    </div>

    <TrackerDetail :tracker="tracker" :trackerInfo="trackerInfo" />

    <ItemTimeLine
      :sortedEvents="tracker.history"
      noEventsMsg="No events for this tracker"
      :allItemTriggers="allTrackerTriggers"
      timelineTitle="History"
      itemType="tracker"
    />
  </div>
</template>

<script>
import ItemActions from "@/components/renderingComponents/ItemActions";
import TrackerDetail from "@/components/renderingComponents/TrackerDetail";
import TriggerWithVolunteer from "@/components/forms/TriggerWithVolunteer";
import GiveTracker from "@/components/forms/GiveTracker";
import ItemTimeLine from "@/components/renderingComponents/ItemTimeline";
import SelectedItemHeader from "@/components/renderingComponents/SelectedItemHeader";
import LinkedItem from "@/components/renderingComponents/LinkedItem";
import StateBadge from "@/components/renderingComponents/StateBadge.vue";
import TagBadge from "@/components/renderingComponents/TagBadge.vue";
import RetrieveTracker from "@/components/forms/RetrieveTracker";

import { retrieveItemByUUID } from "@/common-functions.js";

export default {
  name: "SelectedTracker",
  components: {
    TrackerDetail,
    ItemActions,
    TriggerWithVolunteer,
    ItemTimeLine,
    SelectedItemHeader,
    GiveTracker,
    LinkedItem,
    StateBadge,
    TagBadge,
    RetrieveTracker,
  },
  data() {
    return {
      selectedTrigger: null,
      formSubmitting: false,
      historyTimelineFields: ["trigger"],
      transactionTimelineFields: ["amount_in_pence"],
    };
  },
  props: {
    riders: {
      type: Object,
    },
    tracker: {
      type: Object,
    },
    allTrackerTriggers: {
      type: Object,
    },
    trackerStates: Object,
    trackerInfo: Object,
  },
  computed: {
    preassignedRider() {
      if (this.tracker.pre_assigned_rider) {
        return retrieveItemByUUID(
          this.$store,
          "rider",
          this.tracker.pre_assigned_rider
        );
      }
      return null;
    },
    componentMap() {
      return {
        "trigger-with-volunteer": {
          component: TriggerWithVolunteer,
        },
        "give-tracker": {
          component: GiveTracker,
          props: {
            riders: this.riders,
            preassignedRider: this.preassignedRider,
          },
        },
        "retrieve-tracker": {
          component: RetrieveTracker,
        },
      };
    },
  },
  watch: {
    tracker() {
      this.selectedTrigger = null;
    },
  },
  methods: {
    selectTrigger(trigger) {
      // close trigger form if selecting same button again
      if (
        this.selectedTrigger &&
        this.selectedTrigger.trigger == trigger.trigger
      ) {
        this.selectedTrigger = null;
      } else {
        this.selectedTrigger = trigger;
      }
    },
    submitTrigger(formData) {
      this.formSubmitting = true;
      this.$store
        .dispatch("trackersStore/submitTrackerTrigger", {
          trackerUUID: this.tracker.uuid,
          trigger: this.selectedTrigger.trigger,
          data: formData,
        })
        .then(() => {
          this.$emit("refresh-tracker", this.tracker.uuid);
          this.selectedTrigger = null;
          this.formSubmitting = false;
        })
        .catch((error) => {
          console.error(error);
          this.selectedTrigger = null;
          this.formSubmitting = false;
        });
    },
  },
};
</script>

<style scoped>
.card {
  text-align: left;
}
</style>
