import axios from "axios";

const actions = {
  exportApplicants({ rootState }, raceUUID) {
    let url =
      rootState.baseURL +
      rootState.apiRoutes.races +
      raceUUID +
      "/applicants/export/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  submitApplicantTrigger({ rootState }, { applicantUUID, trigger, data }) {
    let url =
      rootState.baseURL +
      rootState.apiRoutes.applicants +
      applicantUUID +
      "/triggers/" +
      trigger +
      "/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "POST",
        data: data,
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getRaceAdmin({ rootState }, raceUUID) {
    let url = rootState.baseURL + "api/v2/apply/" + raceUUID + "/admin/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getRace({ rootState }, payload) {
    let query_param = payload.token ? "?token=" + payload.token : "";
    let url =
      rootState.baseURL +
      "api/v2/apply/races/" +
      payload.raceShortName +
      query_param;
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createApplicantV2({ rootState }, data) {
    let url = rootState.baseURL + "api/v2/apply/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "POST",
        data: data,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  submitApplicant({ rootState }, applicantUUID) {
    let url = rootState.baseURL + "api/v2/apply/" + applicantUUID + "/submit";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getApplicantV2({ rootState }, applicantUUID) {
    let url = rootState.baseURL + "api/v2/apply/" + applicantUUID;
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  patchApplicantV2({ rootState }, applicantData) {
    let url = rootState.baseURL + "api/v2/apply/" + applicantData.UUID;
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "PATCH",
        data: applicantData.data,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getRaceDetailQuestions({ rootState }, data) {
    let url =
      rootState.baseURL +
      "api/v2/apply/" +
      data.race +
      "/details" +
      "?language=" +
      data.language;
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  postApplicantAnswers({ rootState }, data) {
    let url =
      rootState.baseURL + "api/v2/apply/" + data.applicantUUID + "/answers";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "POST",
        data: data.answer_data,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getApplicantAnswers({ rootState }, applicantUUID) {
    let url = rootState.baseURL + "api/v2/apply/" + applicantUUID + "/answers";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllApplicants({ rootState }, raceUUID) {
    let url = rootState.baseURL + "api/v1/races/" + raceUUID + "/applicants/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getApplicant({ rootState }, applicantUUID) {
    let url =
      rootState.baseURL + rootState.apiRoutes.applicants + applicantUUID + "/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPair({ rootState }, pairUUID) {
    let url = rootState.baseURL + rootState.apiRoutes.pairs + pairUUID + "/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default { namespaced: true, actions };
