<template>
  <TextField
    fieldName="Notes"
    placeholderText="Add notes (optional)"
    :displayLabel="false"
    :textAreaInput="true"
    :value="value"
    @input="updateValue"
  />
</template>

<script>
import TextField from "@/components/formFieldComponents/TextField.vue";

export default {
  name: "NotesField",
  props: {
    value: String,
  },
  methods: {
    updateValue(inputValue) {
      this.$emit("input", inputValue);
    },
  },
  components: {
    TextField,
  },
};
</script>

<style scoped></style>
