<template>
  <Loading v-if="loading" size="100px" />
  <ResponsivePanelsLayout v-else>
    <template v-slot:leftPanelSticky>
      <LiveSearchBar
        @searchTerm="filterTrackers"
        placeholderText="Search trackers"
        @hitEnter="selectTracker(filteredTrackers[0])"
      />
    </template>

    <template v-slot:leftPanelScroll>
      <SelectedTracker
        v-if="selectedTracker"
        :tracker="selectedTracker"
        :riders="riders"
        :allTrackerTriggers="allTrackerTriggers"
        :trackerStates="trackerStates"
        :trackerInfo="trackerInfo"
        @refresh-tracker="getTrackerDetail"
        @clearTracker="selectTracker"
      />
    </template>

    <template v-slot:rightPanel>
      <List
        :cards="filteredTrackers"
        headerField="tcr_id"
        @select-detail="selectTracker"
      >
        <template v-slot:header-badges="{ card }">
          <TagBadge
            class="col col-auto px-1"
            v-if="card.pre_assigned_rider"
            badgeText="Pre-assigned"
            color="info"
          />
          <StateBadge
            class="col col-auto px-1"
            :states="trackerStates"
            :stateKey="card.state"
          />
        </template>
      </List>
    </template>
  </ResponsivePanelsLayout>
</template>

<script>
import Loading from "@/components/renderingComponents/Loading.vue";
import LiveSearchBar from "@/components/renderingComponents/LiveSearchBar";
import List from "@/components/renderingComponents/List";
import SelectedTracker from "@/components/logicComponents/SelectedTracker";
import ResponsivePanelsLayout from "@/components/layoutComponents/ResponsivePanelsLayout";
import TagBadge from "@/components/renderingComponents/TagBadge.vue";
import StateBadge from "@/components/renderingComponents/StateBadge.vue";

import { trackerData } from "@/reference/TrackerReferenceData.js";

export default {
  name: "TrackersPage",
  components: {
    Loading,
    LiveSearchBar,
    List,
    SelectedTracker,
    ResponsivePanelsLayout,
    TagBadge,
    StateBadge,
  },
  data() {
    return {
      trackers: null,
      riders: null,
      selectedTracker: null,
      filteredTrackers: [],
      loading: true,
    };
  },
  computed: {
    allTrackerTriggers() {
      return trackerData.allTrackerTriggers;
    },
    trackerStates() {
      return trackerData.trackerStates;
    },
    trackerInfo() {
      return trackerData.trackerInfo;
    },
    trackersList() {
      return Object.values(this.trackers);
    },
  },
  methods: {
    filterTrackers(searchTerm) {
      this.selectedTracker = null;
      this.filteredTrackers = this.trackersList.filter((tracker) =>
        tracker.tcr_id.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    selectTracker(tracker) {
      if (!tracker) {
        this.selectedTracker = null;
        this.$router.push("/trackers/").catch(() => {});
      } else if (
        this.selectedTracker &&
        this.selectedTracker.uuid == tracker.uuid
      ) {
        // click same tracker in list again to remove detail view
        this.$router.push("/trackers/").catch(() => {});
      } else {
        this.$router.push("/trackers/" + tracker.uuid).catch(() => {});
      }
    },
    getTrackerDetail(uuid) {
      this.$store
        .dispatch("trackersStore/getTrackerDetail", uuid)
        .then((response) => {
          this.selectedTracker = response.data;
          this.trackers[response.data.uuid] = response.data;
          this.filteredTrackers = this.trackersList;
        });
    },
  },
  created() {
    if (this.$route.params) {
      this.$store
        .dispatch(
          "trackersStore/getAllTrackers",
          this.$store.state.racesStore.selectedRace.uuid
        )
        .then((trackers) => {
          this.trackers = trackers;
          this.filteredTrackers = this.trackersList;
          this.$store
            .dispatch(
              "ridersStore/getAllRiders",
              this.$store.state.racesStore.selectedRace.uuid
            )
            .then((riders) => {
              this.riders = riders;
              this.loading = false;
            });
        })
        .then(() => {
          if (!this.$route.params.uuid) {
            this.selectTracker();
          } else {
            this.getTrackerDetail(this.$route.params.uuid);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    this.$watch(
      () => this.$route.params,
      (uuidPath) => {
        if (!uuidPath.uuid) {
          this.selectTracker();
        } else {
          this.getTrackerDetail(uuidPath.uuid);
        }
      }
    );
  },
};
</script>
