<template>
  <div class="card-body bg-light">
    <h5 class="text-left">
      {{ formTitle }}
    </h5>

    <form class="form">
      <ReadOnlyField
        :value="item[displayFieldName]"
        :fieldName="itemType"
        :displayLabel="true"
      />
      <SelectField
        v-if="itemType == 'Tracker'"
        fieldName="Select rider"
        placeholderText="Select rider"
        :displayLabel="false"
        :selectOptions="Object.values(riders)"
        displayFieldName="fullName"
        keyName="uuid"
        v-model="selectedRider"
        :fieldDescription="preassignedLabel"
      />
      <SelectField
        v-else-if="itemType == 'Rider'"
        fieldName="Select tracker"
        placeholderText="Select tracker"
        :displayLabel="false"
        :selectOptions="trackers"
        displayFieldName="tcr_id"
        keyName="uuid"
        v-model="selectedTracker"
        :fieldDescription="preassignedLabel"
      />
      <NotesField v-model="notes" />
      <SubmitButton
        :buttonText="formTitle"
        :loading="loading"
        @submit="emitForm"
      />
    </form>
  </div>
</template>

<script>
import NotesField from "@/components/formFieldComponents/NotesField.vue";
import SelectField from "@/components/formFieldComponents/SelectField.vue";
import ReadOnlyField from "@/components/formFieldComponents/ReadOnlyField.vue";
import SubmitButton from "@/components/formFieldComponents/SubmitButton.vue";

export default {
  name: "GiveTracker",
  props: {
    formTitle: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    item: Object,
    itemType: {
      type: String,
      required: true,
    },
    displayFieldName: String,
    preassignedRider: Object,
    preassignedTracker: Object,
    riders: Object,
    trackers: Array,
  },
  data() {
    return {
      notes: "",
      selectedRider: null,
      selectedTracker: null,
    };
  },
  computed: {
    preassignedLabel() {
      if (
        this.preassignedRider &&
        this.selectedRider == this.preassignedRider
      ) {
        return "Pre-assigned rider";
      } else if (
        this.preassignedTracker &&
        this.selectedTracker == this.preassignedTracker
      ) {
        return "Pre-assigned tracker";
      }
      return null;
    },
  },
  created() {
    if (this.preassignedRider) {
      this.selectedRider = this.preassignedRider;
    } else if (this.preassignedTracker) {
      this.selectedTracker = this.preassignedTracker;
    }
  },
  methods: {
    emitForm() {
      if (this.itemType == "Tracker") {
        this.$emit("form-submission", {
          rider: this.selectedRider.uuid,
          notes: this.notes,
        });
      } else if (this.itemType == "Rider") {
        this.$emit("tracker-form-submission", {
          data: {
            rider: this.item.uuid,
            notes: this.notes,
          },
          tracker: this.selectedTracker.uuid,
        });
      }
    },
  },
  components: {
    NotesField,
    SelectField,
    ReadOnlyField,
    SubmitButton,
  },
};
</script>

<style scoped></style>
