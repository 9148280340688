<template>
  <form>
    <div class="form-group">
      <input
        type="text"
        @focus="$event.target.select()"
        v-model="currentSearchPhrase"
        class="form-control"
        :placeholder="placeholderText"
        @keyup.enter="hitEnter"
      />
    </div>
  </form>
</template>

<script>
export default {
  name: "LiveSearchBar",
  props: {
    placeholderText: {
      type: String,
      default: "Search to filter results",
    },
  },
  computed: {
    currentSearchPhrase: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("searchTerm", value);
      },
    },
  },
  methods: {
    hitEnter() {
      this.$emit("hitEnter");
    },
  },
};
</script>
