<template>
  <div class="card-body py-0 container">
    <div class="row mt-3 px-2">
      <div
        class="col col-auto p-2"
        v-for="trigger in enabledTriggers"
        :key="trigger.verboseName"
      >
        <button
          :class="
            selectedTrigger && trigger == selectedTrigger.trigger
              ? 'btn btn-primary btn-sm px-3'
              : 'btn btn-outline-primary btn-sm px-3'
          "
          @click="
            $emit(
              'select-trigger',
              Object.assign({}, allTriggers[trigger], { trigger: trigger })
            )
          "
        >
          {{ allTriggers[trigger].verboseName }}
        </button>
      </div>
    </div>
    <a
      class="row mt-2 pl-1"
      href=""
      onclick="return false"
      @click="showUnavailableActions = !showUnavailableActions"
      style="color: inherit"
    >
      <div class="col col-auto my-auto pr-0 small">Unavailable actions</div>
      <div class="col pl-2">
        <i
          v-if="!showUnavailableActions"
          class="fas fa-angle-right"
          style="vertical-align: middle"
        ></i>
        <i
          v-if="showUnavailableActions"
          class="fas fa-angle-down"
          style="vertical-align: middle"
        ></i>
      </div>
    </a>
    <div class="row p-2" v-if="showUnavailableActions">
      <div
        class="col col-auto p-2"
        v-for="trigger in disabledTriggers"
        :key="trigger.verboseName"
      >
        <button class="btn btn-outline-secondary btn-sm px-3 disabled">
          {{ allTriggers[trigger].verboseName }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemActions",
  components: {},
  props: {
    itemTriggers: {
      type: Array,
    },
    allTriggers: {
      type: Object,
    },
    selectedTrigger: Object,
  },
  data() {
    return {
      showUnavailableActions: false,
    };
  },
  computed: {
    enabledTriggers() {
      let enabled = Object.keys(this.allTriggers).filter((trigger) =>
        this.itemTriggers.includes(trigger)
      );
      return enabled;
    },
    disabledTriggers() {
      return Object.keys(this.allTriggers).filter(
        (trigger) =>
          !this.itemTriggers.includes(trigger) &&
          this.allTriggers[trigger].userTrigger == true
      );
    },
  },
};
</script>

<style scoped>
.card-body {
  text-align: left;
}

.btn {
  border-radius: 100px;
  box-shadow: none;
}

.disabled {
  cursor: default;
}
</style>
