<template>
  <div :class="formGroupClass">
    <label v-if="displayLabel" :for="fieldName">{{ fieldName }}</label>
    <select
      :class="inputClass"
      :id="fieldName"
      :aria-label="ariaDescription"
      :placeholder="placeholderText"
      v-model="input"
    >
      <option :value="null" disabled selected hidden>
        {{ placeholderText }}
      </option>
      <option
        v-for="option of selectOptions"
        :key="option[keyName]"
        :value="option"
      >
        {{ option[displayFieldName] }}
      </option>
    </select>
    <small v-if="fieldDescription" class="form-text text-muted">{{
      fieldDescription
    }}</small>
  </div>
</template>

<script>
export default {
  name: "SelectField",
  props: {
    fieldName: {
      type: String,
      required: true, // required for aria accessibility
    },
    placeholderText: String,
    fieldDescription: String,
    displayLabel: {
      type: Boolean,
      default: true, // in case we don't want to have the required fieldName displayed
    },
    inputClass: {
      type: String,
      default: "form-control form-control-sm", // for changing size
    },
    formGroupClass: {
      type: String,
      default: "form-group text-left small", // for changing field layouts, default to vertical stack of label, input, description
    },
    selectOptions: Array,
    displayFieldName: String,
    keyName: String,
    value: Object,
  },
  computed: {
    ariaDescription() {
      return this.fieldDescription ? this.fieldDescription : this.fieldName;
    },
    input: {
      get() {
        return this.value;
      },
      set(inputValue) {
        this.$emit("input", inputValue);
      },
    },
  },
};
</script>

<style scoped></style>
