<template>
  <div>
    <b-badge :variant="badgeColor">
      <span v-if="!faIcon">{{ badgeText }}</span
      ><i v-else :class="faIconClass"></i>
    </b-badge>
  </div>
</template>

<script>
export default {
  name: "TagBadge",
  props: {
    badgeText: {
      type: String,
      default: "",
    },
    color: String,
    faIcon: {
      type: String,
      default: "",
    },
  },
  computed: {
    faIconClass() {
      return "fa fa-" + this.faIcon;
    },
    badgeColor() {
      return this.color || "light";
    },
  },
};
</script>
