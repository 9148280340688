<template>
  <div>
    <div class="card-body">
      <table class="table table-sm table-hover">
        <tbody>
          <tr v-for="(value, attribute) in trackerInfo" :key="attribute">
            <td>{{ value.verboseName }}</td>
            <td>
              {{
                displayMethod(tracker[attribute], value.displayRepresentation)
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrackerDetail",
  props: {
    tracker: {
      type: Object,
    },
    trackerInfo: Object,
  },
  methods: {
    clearTracker() {
      this.$emit("clearTracker");
    },
    displayMethod(value, displayCallback) {
      if (displayCallback) {
        return displayCallback(value);
      }
      return value;
    },
  },
};
</script>

<style scoped>
.card-header,
.card-body {
  text-align: left;
}

.close-button {
  cursor: pointer;
}
</style>
