<template>
  <b-container class="mt-5">
    <Loading v-if="!race" />
    <b-card :img-src="race.logo_link" img-top class="apply-flow-card">
      <template v-if="title" #header>
        <h1 class="mb-0 h2 text-center">{{ title }}</h1>
      </template>
      <template v-else #header>
        <h1 class="mb-0 h2 text-center">{{ race.long_name }} Application</h1>
      </template>
      <slot v-if="race" name="body"> </slot>
    </b-card>
    <p class="mt-3">
      made by
      <b-link href="https://racebuddy.co.uk" target="_blank">Racebuddy</b-link>
      on behalf of
      <b-link :href="race.race_link" target="_blank">{{
        race.long_name
      }}</b-link>
      <br />
      help@racebuddy.co.uk //
      {{ race.race_email }}
    </p>
  </b-container>
</template>

<script>
import Loading from "@/components/renderingComponents/Loading.vue";
export default {
  name: "ApplyFlowLayout",
  components: { Loading },
  props: {
    race: Object,
    title: String,
  },
};
</script>

<style>
p {
  white-space: pre-line;
}
.card-header {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.apply-flow-card {
  text-align: left;
  max-width: 700px;
  margin: 20px auto;
}
.apply-flow-card .card-img-top {
  width: 80%;
  max-width: 400px;
  margin: 20px auto;
}
form {
  margin: 30px 0;
}
label {
  font-weight: 600;
}
</style>
