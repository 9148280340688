<template>
  <div class="card-body bg-light">
    <h5 class="text-left">
      {{ formTitle }}
    </h5>

    <form class="form">
      <ReadOnlyField
        :value="item[displayFieldName]"
        :fieldName="itemType"
        :displayLabel="true"
      />
      <NotesField v-model="notes" />
      <SubmitButton
        :buttonText="formTitle"
        :loading="loading"
        @submit="emitForm"
      />
    </form>
  </div>
</template>

<script>
import NotesField from "@/components/formFieldComponents/NotesField.vue";
import ReadOnlyField from "@/components/formFieldComponents/ReadOnlyField.vue";
import SubmitButton from "@/components/formFieldComponents/SubmitButton.vue";

export default {
  name: "TriggerWithVolunteer",
  props: {
    formTitle: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    item: Object,
    itemType: String,
    displayFieldName: String,
  },
  data() {
    return {
      notes: "",
    };
  },
  computed: {},
  methods: {
    emitForm() {
      this.$emit("form-submission", {
        notes: this.notes,
      });
    },
  },
  components: {
    NotesField,
    ReadOnlyField,
    SubmitButton,
  },
};
</script>

<style scoped></style>
