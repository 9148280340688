import Vue from "vue";
import VueRouter from "vue-router";
import Arrivals from "../pages/Arrivals.vue";
import store from "../store/ApplicationStore";
import Login from "../pages/Login.vue";
import RidersPage from "../pages/Riders.vue";
import TrackersPage from "../pages/Trackers.vue";
import UsersPage from "../pages/Users.vue";
import ApplicationsPage from "../pages/Applications.vue";
import ApplyCreate from "../pages/apply/Create.vue";
import ApplyDetails from "../pages/apply/Details.vue";
import ApplyQuestions from "../pages/apply/Questions.vue";
import ApplyCheckEmail from "../pages/apply/CheckEmail.vue";
import ThankYou from "../pages/apply/ThankYou.vue";
import SoldOut from "../pages/apply/SoldOut.vue";
import ChangePasswordPage from "../pages/ChangePassword.vue";
import PageNotFound from "../pages/PageNotFound.vue";
import Race from "../pages/Race.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/races",
    name: "races",
    component: Race,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/arrivals",
    name: "arrivals",
    component: Arrivals,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/riders/:uuid?",
    name: "riders",
    component: RidersPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/trackers/:uuid?",
    name: "trackers",
    component: TrackersPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/applications",
    name: "applications",
    component: ApplicationsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/apply/:raceShortName(.{1,10})/thank-you",
    name: "applyThankYou",
    component: ThankYou,
    meta: {
      hideNav: true,
    },
  },
  {
    path: "/apply/:raceShortName(.{1,10})/check-email",
    name: "applyCheckEmail",
    component: ApplyCheckEmail,
    meta: {
      hideNav: true,
    },
  },
  {
    path: "/apply/:raceShortName(.{1,10})/create",
    name: "applyCreate",
    component: ApplyCreate,
    meta: {
      hideNav: true,
    },
  },
  {
    path:
      "/apply/:raceShortName(.{1,10})/:uuid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/details",
    name: "applyDetails",
    component: ApplyDetails,
    meta: {
      hideNav: true,
    },
  },
  {
    path:
      "/apply/:raceShortName(.{1,10})/:uuid([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/questions",
    name: "applyQuestions",
    component: ApplyQuestions,
    meta: {
      hideNav: true,
    },
  },
  {
    path: "/apply/sold_out",
    name: "soldOut",
    component: SoldOut,
    meta: {
      hideNav: true,
    },
  },
  {
    path: "/change_password",
    name: "change_password",
    component: ChangePasswordPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "users",
    component: UsersPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "*",
    name: "notFound",
    component: PageNotFound,
    meta: {
      hideNav: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  // base: "",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.auth.logged_in) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
