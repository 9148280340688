<template>
  <div>
    <Loading v-if="loading" size="100px" />
    <ResponsivePanelsLayout v-else>
      <template v-slot:leftPanelSticky>
        <SelectControlPoint
          :defaultSelection="$store.state.arrivalsStore.selectedControlPoint"
          :options="controlPoints"
          @select-control-point="selectControlPoint"
        />

        <LiveSearchBar
          @searchTerm="filterRiders"
          placeholderText="Search riders"
          @hitEnter="selectRiderWithEnter"
        />

        <div class="card text-left" v-if="selectedRider">
          <SelectedItemHeader
            :cardHeader="selectedRider.searchString"
            @clear="selectRider"
          />

          <RegisterArrival
            @submit-arrival="submitArrival"
            @update-arrival="updateArrival"
            :rider="selectedRider"
            :controlPoint="selectedControlPoint"
            :loading="submitting"
          />
        </div>
      </template>

      <!-- <template v-slot:leftPanelScroll>

    </template> -->

      <template v-slot:rightPanel>
        <ArrivalsList
          :arrivedRiders="arrivedRiders"
          :disqualifiedRiders="disqualifiedRiders"
          :activeRidersForControlPoint="activeRidersForControlPoint"
          :activeRidersBehindControlPoint="activeRidersBehindControlPoint"
          :states="riderStates"
          headerField="searchString"
          :controlPoint="selectedControlPoint"
          @select-rider="selectRider"
        />
      </template>
    </ResponsivePanelsLayout>

    <b-modal
      id="arrival-modal"
      :title="riderArrivalName"
      ok-only
      hide-header-close
      ok-variant="secondary"
      >{{ riderArrivalString }}</b-modal
    >
  </div>
</template>

<script>
import Loading from "@/components/renderingComponents/Loading";
import SelectControlPoint from "@/components/renderingComponents/SelectControlPoint";
import ArrivalsList from "@/components/renderingComponents/ArrivalsList";
import RegisterArrival from "@/components/logicComponents/RegisterArrival";
import ResponsivePanelsLayout from "@/components/layoutComponents/ResponsivePanelsLayout";
import LiveSearchBar from "@/components/renderingComponents/LiveSearchBar";
import SelectedItemHeader from "@/components/renderingComponents/SelectedItemHeader";

import { riderData } from "@/reference/RiderReferenceData.js";

export default {
  name: "Arrivals",
  data() {
    return {
      controlPoints: [],
      allRiders: {},
      arrivalsAtControlPoint: [],
      filteredRiders: [],
      selectedRider: null,
      selectedArrival: null,
      loading: true,
      submitting: false,
      riderArrivalString: "",
      riderArrivalName: "",
    };
  },
  components: {
    Loading,
    SelectControlPoint,
    RegisterArrival,
    ResponsivePanelsLayout,
    LiveSearchBar,
    ArrivalsList,
    SelectedItemHeader,
  },
  methods: {
    setArrivals() {
      this.arrivalsAtControlPoint = null;
      this.loading = true;
      this.$store
        .dispatch("arrivalsStore/getArrivals", this.selectedControlPoint.uuid)
        .then((response) => {
          this.arrivalsAtControlPoint = response;
          this.loading = false;
        });
    },
    selectControlPoint(value) {
      this.$store.commit("arrivalsStore/setControlPoint", value);
      this.selectedRider = null;
      this.setArrivals();
    },
    setControlPointToPreferred() {
      if (this.$store.state.usersStore.me.preferred_control_point) {
        let filtered_control_point_by_preferred = this.controlPoints.filter(
          (controlpoint) =>
            controlpoint.uuid ==
            this.$store.state.usersStore.me.preferred_control_point
        );
        this.selectControlPoint(filtered_control_point_by_preferred[0]);
      }
    },
    selectRider(rider) {
      if (!rider) {
        this.selectedRider = null;
      } else if (this.selectedRider && this.selectedRider.uuid == rider.uuid) {
        // click same rider in list again to remove detail view
        this.selectedRider = null;
      } else if (
        this.checkArrayIncludes(
          this.activeRidersForControlPoint,
          "uuid",
          rider.uuid
        ) ||
        this.checkArrayIncludes(this.arrivedRiders, "uuid", rider.uuid)
      ) {
        this.selectedRider = rider;
      }
    },
    submitArrival(formData) {
      this.submitting = true;
      this.$store
        .dispatch("arrivalsStore/newArrival", formData)
        .then((res) => {
          this.$bvModal.show("arrival-modal");
          this.riderArrivalName = this.selectedRider.fullName;
          this.riderArrivalString = res.data.time_passed;
          this.submitting = false;
          this.selectedRider = null;
          this.setArrivals();
          this.filterRiders("");
        })
        .catch((err) => {
          this.submitting = false;
        });
    },
    updateArrival(formData) {
      this.submitting = true;
      this.$store
        .dispatch("arrivalsStore/updateArrival", formData)
        .then((res) => {
          this.$bvModal.show("arrival-modal");
          this.riderArrivalName = this.selectedRider.fullName;
          this.riderArrivalString = res.data.time_passed;
          this.submitting = false;
          this.selectedRider = null;
          this.setArrivals();
          this.filterRiders("");
        })
        .catch(() => {
          this.submitting = false;
        });
    },
    filterRiders(searchTerm) {
      this.selectedRider = null;
      if (searchTerm.length != 0) {
        this.filteredRiders = Object.values(this.allRiders).filter((rider) =>
          rider.searchString.toLowerCase().includes(searchTerm.toLowerCase())
        );
      } else {
        this.filteredRiders = Object.values(this.allRiders);
      }
    },
    checkArrayIncludes(array, checkFieldName, checkValue) {
      let arrayOfCheckFields = array.map((item) => item[checkFieldName]);
      return arrayOfCheckFields.includes(checkValue);
    },
    selectRiderWithEnter() {
      if (this.arrivedRiders.length > 0) {
        this.selectRider(this.arrivedRiders[0]);
      } else if (this.activeRidersForControlPoint.length > 0) {
        this.selectRider(this.activeRidersForControlPoint[0]);
      } else return null;
    },
  },
  computed: {
    selectedControlPoint() {
      return this.$store.state.arrivalsStore.selectedControlPoint;
    },
    arrivedRiders() {
      // riders who have arrived at CP
      if (!this.arrivalsAtControlPoint) {
        return [];
      }
      // find all riders who have arrived
      let arrivedRiders = this.filteredRiders.filter((rider) =>
        this.checkArrayIncludes(
          this.arrivalsAtControlPoint,
          "rider",
          rider.uuid
        )
      );
      // add the relevant arrival object to each rider
      let arrivedRidersWithArrival = arrivedRiders.map((rider) => ({
        ...rider,
        arrival: this.arrivalsAtControlPoint.find(
          (element) => element.rider == rider.uuid
        ),
      }));
      // sort array by arrival position
      let sortedArrivedRiders = arrivedRidersWithArrival
        .slice()
        .sort((a, b) => {
          if (a.arrival.position > b.arrival.position) return 1;
          else return -1;
        });
      return sortedArrivedRiders;
    },
    disqualifiedRiders() {
      // riders who aren't active and who haven't arrived at CP
      return this.filteredRiders.filter(
        (rider) =>
          rider.state != "active" &&
          !this.checkArrayIncludes(this.arrivedRiders, "uuid", rider.uuid)
      );
    },
    activeRidersForControlPoint() {
      // active riders who haven't arrived, aren't disqualified and whose latest CP is one behind this CP
      return this.filteredRiders.filter(
        (rider) =>
          !this.checkArrayIncludes(
            this.arrivedRiders.concat(this.disqualifiedRiders),
            "uuid",
            rider.uuid
          ) && rider.arrivals.length == this.selectedControlPoint.order - 1
      );
    },
    activeRidersBehindControlPoint() {
      // active riders who haven't arrived, aren't disqualified but who aren't going for this control point yet
      return this.filteredRiders.filter(
        (rider) =>
          !this.checkArrayIncludes(
            this.arrivedRiders.concat(
              this.disqualifiedRiders,
              this.activeRidersForControlPoint
            ),
            "uuid",
            rider.uuid
          )
      );
    },
    riderStates() {
      return riderData.riderStates;
    },
  },
  created() {
    this.$store.dispatch("usersStore/getMe").then(() => {
      Promise.all([
        this.$store.dispatch(
          "arrivalsStore/getControlPoints",
          this.$store.state.racesStore.selectedRace.uuid
        ),
        this.$store.dispatch(
          "ridersStore/getAllRiders",
          this.$store.state.racesStore.selectedRace.uuid
        ),
      ]).then((values) => {
        let [controlPoints, riders] = values;
        this.controlPoints = controlPoints;
        this.allRiders = riders;
        this.setControlPointToPreferred();
        this.setArrivals();
        this.filterRiders("");
        this.loading = false;
      });
    });
  },
};
</script>
