<template>
  <div class="card border-light mb-1">
    <div class="card-body p-2">
      <div class="row">
        <div class="col-2 mb-auto p-2 text-center">
          <i class="fa fa-satellite-dish" style="color: grey"></i>
        </div>

        <div class="col mb-auto pt-2">
          <div class="row mb-3">
            <div class="col">
              <router-link :to="{ path: trackerURL }">
                {{ event.tracker.tcr_id }}
              </router-link>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <table class="table table-sm table-hover">
                <tbody>
                  <tr>
                    <td>Received</td>
                    <td>
                      <div class="small text-muted">
                        {{ possessionStartTime[0] }}
                      </div>
                      <div class="small text-muted">
                        {{ possessionStartTime[1] }}
                      </div>
                    </td>
                  </tr>
                  <tr v-if="possessionEndTime">
                    <td>Returned</td>
                    <td>
                      <div class="small text-muted">
                        {{ possessionEndTime[0] }}
                      </div>
                      <div class="small text-muted">
                        {{ possessionEndTime[1] }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemTimelineAssignment",
  props: {
    event: Object,
  },
  computed: {
    possessionStartTime() {
      return [
        new Date(this.event.possession_start).toLocaleDateString("en-gb"),
        new Date(this.event.possession_start).toLocaleTimeString("en-gb"),
      ];
    },
    possessionEndTime() {
      if (this.event.possession_end) {
        return [
          new Date(this.event.possession_end).toLocaleDateString("en-gb"),
          new Date(this.event.possession_end).toLocaleTimeString("en-gb"),
        ];
      }
      return null;
    },
    trackerURL() {
      return "/trackers/" + this.event.tracker.uuid;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
