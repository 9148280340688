<template>
  <div class="boxOfCards text-left">
    <div class="row">
      <h5 class="col">{{ controlPoint.name }}</h5>
      <h5 class="col col-auto text-muted">
        Arrivals: {{ arrivedRiders.length }}
      </h5>
    </div>
    <div class="row">
      <h6 class="col">
        <i class="fa fa-clock-o mr-2"></i>{{ controlPoint.timezone }}
      </h6>
    </div>
    <hr class="mt-0" />

    <div v-if="allRiders.length == 0" class="card bg-light">
      <div class="card-body py-3">
        <div class="text-muted">
          <i>{{ emptyStateText }}</i>
        </div>
      </div>
    </div>

    <div
      v-for="rider in arrivedRiders"
      :key="rider.uuid"
      class="card"
      @click="$emit('select-rider', rider)"
    >
      <div class="card-body py-3">
        <div class="row">
          <div class="col">{{ rider[headerField] }}</div>
          <div class="col col-auto">
            <b>{{ toPositionString(rider.arrival.position) }}</b>
          </div>
          <div class="col col-auto">
            <ToolTip
              v-if="rider.requires_attention"
              toolTipText="Requires Attention!"
            >
              <TagBadge
                class="col col-auto px-1"
                fa-icon="flag"
                color="danger"
              />
            </ToolTip>
          </div>
          <div class="col col-auto">
            <StateBadge :states="states" :stateKey="rider.state" />
          </div>
        </div>
        <div class="row">
          <div class="col mb-2 text-muted">{{ rider.arrival.time_passed }}</div>
        </div>
      </div>
    </div>

    <div
      v-for="rider in activeRidersForControlPoint"
      :key="rider.uuid"
      class="card"
      @click="$emit('select-rider', rider)"
    >
      <div class="card-body py-3 row">
        <div class="col">{{ rider[headerField] }}</div>
        <div class="col col-auto">-</div>
        <div class="col col-auto">
          <ToolTip
            v-if="rider.requires_attention"
            toolTipText="Requires Attention!"
          >
            <TagBadge class="col col-auto px-1" fa-icon="flag" color="danger" />
          </ToolTip>
        </div>
        <div class="col col-auto">
          <StateBadge :states="states" :stateKey="rider.state" />
        </div>
      </div>
    </div>

    <div
      v-for="rider in activeRidersBehindControlPoint"
      :key="rider.uuid"
      class="card text-muted bg-light"
      @click="$emit('select-ineligible')"
    >
      <div class="card-body py-3 row">
        <div class="col">{{ rider[headerField] }}</div>
        <div class="col col-auto">
          <ToolTip
            v-if="rider.requires_attention"
            toolTipText="Requires Attention!"
          >
            <TagBadge class="col col-auto px-1" fa-icon="flag" color="danger" />
          </ToolTip>
        </div>
        <div class="col col-auto">
          <StateBadge :states="states" :stateKey="rider.state" />
        </div>
      </div>
    </div>

    <div
      v-for="rider in disqualifiedRiders"
      :key="rider.uuid"
      class="card text-muted bg-light"
      @click="$emit('select-disqualified')"
    >
      <div class="card-body py-3 row">
        <div class="col">{{ rider[headerField] }}</div>
        <div class="col col-auto">
          <ToolTip
            v-if="rider.requires_attention"
            toolTipText="Requires Attention!"
          >
            <TagBadge class="col col-auto px-1" fa-icon="flag" color="danger" />
          </ToolTip>
        </div>
        <div class="col col-auto">
          <StateBadge :states="states" :stateKey="rider.state" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StateBadge from "@/components/renderingComponents/StateBadge.vue";
import ToolTip from "@/components/renderingComponents/ToolTip.vue";
import TagBadge from "@/components/renderingComponents/TagBadge.vue";

import { toPositionString } from "@/common-functions.js";

export default {
  name: "ArrivalsList",
  props: {
    arrivedRiders: Array,
    disqualifiedRiders: Array,
    activeRidersForControlPoint: Array,
    activeRidersBehindControlPoint: Array,
    states: Object,
    headerField: String,
    controlPoint: Object,
    emptyStateText: {
      type: String,
      default: "No riders matching search terms",
    },
  },
  data() {
    return {
      toPositionString: toPositionString,
    };
  },
  components: {
    StateBadge,
    ToolTip,
    TagBadge,
  },
  computed: {
    allRiders() {
      return this.arrivedRiders.concat(
        this.activeRidersForControlPoint,
        this.activeRidersBehindControlPoint,
        this.disqualifiedRiders
      );
    },
  },
};
</script>

<style scoped>
.card {
  margin-bottom: 4px;
  text-align: left;
  cursor: pointer;
}
.boxOfCards {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

hr {
  width: 100%;
  height: 1px;
}
</style>
