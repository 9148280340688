<template>
  <div class="card-body">
    <h5>{{ timelineTitle }}</h5>
    <div v-if="sortedEvents">
      <div v-for="event in sortedEvents" :key="event.uuid">
        <component
          :is="componentMap[event.eventType].component"
          :event="event"
          :users="allUsers"
          :itemType="itemType"
          v-bind="componentMap[event.eventType].props"
        />
      </div>
    </div>
    <div v-else class="card bg-light border-light">
      <div class="card-body text-muted">
        <i>{{ noEventsMsg }}</i>
      </div>
    </div>
  </div>
</template>

<script>
import ItemTimelineEvent from "@/components/renderingComponents/ItemTimelineEvent.vue";
import DocumentUpload from "@/components/renderingComponents/DocumentUpload.vue";
import ItemTimelineTransaction from "@/components/renderingComponents/ItemTimelineTransaction.vue";
import ItemTimelineAssignment from "@/components/renderingComponents/ItemTimelineAssignment.vue";
import { listToDict } from "../../common-functions.js";

export default {
  name: "ItemTimeLine",
  props: {
    sortedEvents: {
      type: Array,
    },
    noEventsMsg: {
      type: String,
    },
    allItemTriggers: Object,
    timelineTitle: String,
    itemType: String,
  },
  computed: {
    allUsers() {
      return listToDict(this.$store.state.usersStore.users);
    },
  },
  data() {
    return {
      componentMap: {
        document_upload: {
          component: DocumentUpload,
        },
        event: {
          component: ItemTimelineEvent,
          props: {
            allItemTriggers: this.allItemTriggers,
          },
        },
        transaction: {
          component: ItemTimelineTransaction,
          props: {
            allItemTriggers: this.allItemTriggers,
          },
        },
        arrival: {
          component: ItemTimelineEvent,
          props: {
            allItemTriggers: this.allItemTriggers,
          },
        },
        assignment: {
          component: ItemTimelineAssignment,
          props: {},
        },
      },
    };
  },
  components: {
    ItemTimelineEvent,
    ItemTimelineTransaction,
    ItemTimelineAssignment,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
