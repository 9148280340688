<template>
  <div class="messageBar">
    <div
      v-for="message in messages"
      :key="message.messageID"
      class="alert"
      :class="alertClass(message.status)"
    >
      {{ message.status > 200 ? message.status : "" }}
      {{ message.message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageBar",
  props: {
    messages: {
      type: Array,
    },
  },
  methods: {
    alertClass(status) {
      if (status >= 200 && status < 300) {
        return "alert-success";
      }
      return "alert-danger";
    },
  },
};
</script>
<style scoped>
.alert {
  width: 100%;
}
.messageBar {
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  left: 35px;
  bottom: 10px;
  z-index: 100;
}
</style>
