import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/ApplicationStore";
import axios from "axios";
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.prototype.$http = axios;
const access = localStorage.getItem("access");
if (access) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Bearer " + access;
}

Vue.use(BootstrapVue);

store.dispatch("setBaseURL").then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}),
  (Vue.config.productionTip = false);
axios.interceptors.response.use(
  (response) => {
    if (response.config.method != "get") {
      store.dispatch("newMessage", {
        status: response.status,
        message: "👍",
      });
    }
    return response;
  },
  (error) => {
    if (error.response.status != 401) {
      return new Promise((resolve, reject) => {
        store.dispatch("newMessage", {
          status: error.response.status,
          message: error.response.data,
        });
        reject(error);
      });
    }
    if (
      error.config.url ==
      store.state.baseURL + store.state.apiRoutes.refresh
    ) {
      store.commit("auth_logout");
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    if (error.config.url == store.state.baseURL + store.state.apiRoutes.token) {
      return new Promise((resolve, reject) => {
        store.dispatch("newMessage", {
          status: error.response.status,
          message: "Error logging in, check credentials.",
        });
        reject(error);
      });
    }
    return store
      .dispatch("auth_refresh")
      .then((token) => {
        const config = error.config;
        config.headers["Authorization"] = `Bearer ${token}`;
        return new Promise((resolve, reject) => {
          axios
            .request(config)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      })
      .catch((error) => {
        Promise.reject(error);
      });
  }
);
