import axios from "axios";
// import { listToDict } from "../common-functions";

const state = {
  selectedControlPoint: null,
};

const mutations = {
  setControlPoint(state, controlPoint) {
    state.selectedControlPoint = controlPoint;
  },
};

const actions = {
  newArrival({ rootState }, arrivalData) {
    let url = rootState.baseURL + rootState.apiRoutes.arrivals;
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "POST",
        data: arrivalData,
      })
        .then((resp) => {
          this.dispatch("ridersStore/getRiderDetail", arrivalData.rider).then(
            () => {
              resolve(resp);
            }
          );
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateArrival({ rootState }, arrivalData) {
    let url =
      rootState.baseURL + rootState.apiRoutes.arrivals + arrivalData.uuid + "/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "PUT",
        data: arrivalData,
      })
        .then((resp) => {
          this.dispatch("ridersStore/getRiderDetail", arrivalData.rider).then(
            () => {
              resolve(resp);
            }
          );
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getArrivals({ rootState }, controlPointUUID) {
    let url =
      rootState.baseURL +
      rootState.apiRoutes.arrivals +
      "?controlpoint=" +
      controlPointUUID;
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          let arrivals = resp.data;
          resolve(arrivals);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getControlPoints({ commit, rootState }, raceUUID) {
    let url =
      rootState.baseURL + "api/v1/races/" + raceUUID + "/controlpoints/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          let controlPoints = resp.data;
          commit("setControlPoint", controlPoints[0]);
          resolve(controlPoints);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default { namespaced: true, actions, state, mutations };
