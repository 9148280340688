<template>
  <b-navbar toggleable="lg" type="dark" variant="dark" fixed="top">
    <div class="container">
      <b-navbar-brand class="p-0" style="font-family: 'Rubik'" to="/races">
        <img
          src="@/assets/rb-logo.svg"
          alt="RaceBuddy Logo"
          class="racebuddy-logo"
        />
        <b>race</b>buddy
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item v-if="selectedRace" to="/races">{{ selectedRace }}</b-nav-item>
          <b-nav-item
            v-for="(module, index) in availableModules"
            :key="index"
            :to="module.page_route"
            >{{ module.menu_name }}</b-nav-item
          >
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <template v-if="$store.state.auth.logged_in">
            <b-nav-item disabled>{{ fullName }}</b-nav-item>
            <b-nav-item to="/change_password">Change Password</b-nav-item>
            <b-nav-item @click="logout">Logout</b-nav-item>
          </template>
          <b-nav-item v-else :href="forgotPasswordUrl"
            >Forgot Password</b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    fullName: String,
    selectedRace: String,
    availableModules: Array,
  },
  computed: {
    forgotPasswordUrl() {
      const { baseURL, apiRoutes } = this.$store.state;
      return baseURL + apiRoutes.forgot_password;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style scoped>
.navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: #57c292;
}

a.nav-link.router-link-exact-active {
  color: #42b983;
}

.racebuddy-logo {
  height: 70px;
  margin-right: 10px;
}
</style>
