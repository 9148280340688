<template>
  <div class="loading">
    <div class="mx-2">
      <div class="compass-container" :style="`width: ${size}; height: ${size}`">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="compass-outline"
          viewBox="266.45 260.85 106.27 106.27"
        >
          <ellipse
            class="outline-circle"
            style="
              stroke-miterlimit: 3;
              stroke-linecap: round;
              stroke-width: 5px;
            "
            cx="319.578"
            cy="313.969"
            rx="48.115"
            ry="48.115"
          ></ellipse>
          <path
            d="M272.14 325.92c-1-3.69-1.76-7.36-1.24-11.18 0-.17.47-.28 1-.56.18 1.66.3 3.11.5 4.54.27 2 .6 3.9.91 5.85.1.84.11.84-1.17 1.35Zm81.39 23.02-8.7 6.78c-1-.65-1.14-1.12-.31-1.72 1.85-1.35 3.71-2.71 5.53-4.1.69-.53 1.24-1.24 1.95-1.72.31-.21.85-.08 1.29-.1ZM365 326.63a7.48 7.48 0 0 1 .78.19 4.16 4.16 0 0 1 .64.5l-4.19 10.06c-1.34 0-1.53-.43-1.09-1.35.78-1.64 1.55-3.3 2.25-5 .61-1.37 1.04-2.8 1.61-4.4Zm-88.26-36.62 1.11.52c-1.31 3.36-2.56 6.62-3.88 9.84-.12.29-.72.39-1.26.4a46.94 46.94 0 0 1 4.03-10.76Zm1.54 47.81c1.21 1.73 2 3 3 4.17q1.68 2.17 3.53 4.22c.63.7.76 1.18-.37 1.7a42.26 42.26 0 0 1-6.53-8.36c-.2-.25.09-.81.37-1.73Zm17.39-65.29-9.3 7.09c-.52-.24-1.18-.66-.26-1.39 2.53-2 5.13-4 7.74-5.88.26-.19.85.07 1.82.18Zm24.02 89.65c.71-.56 1-1 1.39-1.07 3.37-.37 6.75-.67 10.41-1-.15.4-.21 1.11-.46 1.18a26.06 26.06 0 0 1-11.34.89Zm40.66-72.56c-2.37-2.93-4.58-5.61-6.71-8.34-.2-.26.05-.86.14-1.86 3 3.18 5.53 6 7.15 9.42.07.12-.32.45-.58.78Zm6.37 23.35c-.16-1.43-.29-2.84-.48-4.23-.24-1.81-.47-3.63-.82-5.42-.19-.94 0-1.37 1.15-1.3a27.67 27.67 0 0 1 1.3 10.28.82.82 0 0 1-.35.55 2.1 2.1 0 0 1-.8.12Zm-24.15-40.63-9.48-3.68c-.61-.24-.6-.25-.34-1.61a59.36 59.36 0 0 1 9.67 3.84c.75.36.8.92.15 1.45Zm-35.1-5.17a20.85 20.85 0 0 1 11.27-1.66c.35.8.09 1.16-.62 1.24-3.21.35-6.43.71-9.65 1-.31.03-.66-.38-1-.58Zm-.91 92.58c.08.88-.32 1.28-1.12 1-3-1.18-6-2.4-9-3.69-.31-.13-.41-.77-.6-1.17l.37-.33Z"
            class="outline-dashes"
          ></path>
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="compass-needle"
          viewBox="278.37 296.72 81.27 33.87"
        >
          <path
            d="M359.61 297.55c-.73.5-1.46 1.05-2.24 1.51l-26.56 15.76c-2.26 1.34-4.56 2.64-6.75 4.09a20 20 0 0 1-6.61 2.66l-35.88 8.42c-1 .23-2 .39-2.93.57-.71-1.32.23-1.58.87-2q4.77-2.75 9.56-5.47c8.19-4.63 16.45-9.15 24.56-13.93 3.05-1.8 6.38-2.48 9.67-3.36 11.41-3 22.85-5.94 34.28-8.91.69-.14 1.42-.52 2.03.66Zm-38.35 14.15.72.77a5.31 5.31 0 0 0 1.46 4.82 8.19 8.19 0 0 0-1.5-4.78l-.41-1a6.45 6.45 0 0 0-1.73-3.73c-.05 1.89.37 3.03 1.46 3.92Zm20.36-6.9-1.77-2.24-.15 2.24-1.81-1.69a6.87 6.87 0 0 0 2.06 4.6c-.09-1-.19-1.95-.28-2.92.55.69 1.1 1.37 1.92 2.41v-2.4l1.7 1.3a4.37 4.37 0 0 0-1.67-3.8Zm-24.45 4.93-.61-.33q-16.5 8.88-32.73 18.32c12.28-2.66 24.44-5.61 36.76-8.72l-.91-2.12c-3.6-.43-4.52-2.25-2.9-5.26a6.75 6.75 0 0 0 .39-1.89Zm7.8 6.62c.18-1.92-1.87-7.13-3.32-8.42a32.69 32.69 0 0 0 3.35 8.42Zm-1.08-9.57c-.35 1.66 1.2 6.62 2.35 8.38.1.16.47.15 1.11.33Zm4.35 8.28c.32-2.32-1.67-7.25-3.2-8.21a78.66 78.66 0 0 0 3.2 8.26Zm-1-8.65a17.09 17.09 0 0 0 2.79 7.08 15.28 15.28 0 0 0-2.78-7.08Zm1.78-.52c0 2.07 1.54 5.81 2.76 6.55-.26-2.41-1.53-5.42-2.78-6.55Zm2-.46c-.31 1.37 1 4.8 2.26 6.07a11.57 11.57 0 0 0-2.28-6.07Zm3.85 5.56c.18-2.14-1-5.07-2.43-6.14a18.33 18.33 0 0 0 2.43 6.14Zm1.65-1.09a6.58 6.58 0 0 0-2.11-5.33 11.25 11.25 0 0 0 2.11 5.33Zm2-1.11a13.12 13.12 0 0 0-2.06-5.2 5.86 5.86 0 0 0 2.02 5.2Zm-20-.11-.63.23a2 2 0 0 0 1.61 2.13c-.42-1.03-.69-1.7-.97-2.36Zm25.48-6.74-.59.23a2.32 2.32 0 0 0 1.63 2.49Zm1.8-.51-.66.26a2.44 2.44 0 0 0 1.76 2.42c-.49-1.18-.79-1.93-1.08-2.68Zm-23.76 17c0-1.09 0-1.93-1.13-2.51-.14 1.13-.09 1.92 1.15 2.53Zm25.67-15.18c.43-1.43.43-1.43-1-2.49Zm1.44-1.33.53-.07c.24-.62.21-1.12-.95-1.43.2.69.32 1.1.44 1.52Zm1.89-2.09c-.24.26-.52.47-.49.54a3.47 3.47 0 0 0 .52.64c.15-.15.44-.32.42-.42a2.5 2.5 0 0 0-.43-.74Z"
            class="cls-2"
          ></path>
          <path
            d="M317.17 309.73a6.75 6.75 0 0 1-.39 1.89c-1.62 3-.7 4.83 2.9 5.26l.91 2.12c-12.32 3.11-24.48 6.06-36.76 8.72q16.25-9.47 32.73-18.32Zm7.83 6.62a32.69 32.69 0 0 1-3.32-8.42c1.42 1.29 3.47 6.5 3.32 8.42Zm-1.11-9.57 3.46 8.71c-.64-.18-1-.17-1.11-.33-1.15-1.76-2.7-6.72-2.35-8.38Zm4.35 8.33a78.66 78.66 0 0 1-3.2-8.21c1.53.91 3.52 5.84 3.2 8.21Zm-.99-8.7a15.28 15.28 0 0 1 2.79 7.08 17.09 17.09 0 0 1-2.79-7.08Zm1.75-.52c1.22 1.13 2.49 4.14 2.76 6.55-1.19-.74-2.76-4.48-2.76-6.55Zm2-.46a11.57 11.57 0 0 1 2.26 6.07c-1.26-1.27-2.55-4.7-2.26-6.07Zm3.87 5.56a18.33 18.33 0 0 1-2.43-6.14c1.43 1.07 2.61 4 2.43 6.14Zm1.65-1.09a11.25 11.25 0 0 1-2.11-5.33 6.58 6.58 0 0 1 2.11 5.33Zm1.96-1.11a5.86 5.86 0 0 1-2.06-5.2 13.12 13.12 0 0 1 2.06 5.2Zm1.19-4.03c.09 1 .19 2 .28 2.92a6.87 6.87 0 0 1-2.06-4.6l1.81 1.72Zm1.92.01v2.4c-.82-1-1.37-1.72-1.92-2.41 0-.59.08-1.18.15-2.24l1.77 2.24Zm.03.03v-2.53a4.37 4.37 0 0 1 1.67 3.8l-1.7-1.3Zm-19.68 7.71a8.19 8.19 0 0 1 1.5 4.78 5.31 5.31 0 0 1-1.46-4.82Zm-.69-.81c-1.08-.89-1.5-2-1.45-3.95a6.45 6.45 0 0 1 1.73 3.73Zm-2.72-3.02c.28.66.55 1.33 1 2.36a2 2 0 0 1-1.61-2.13Zm25.47-6.74 1 2.72a2.32 2.32 0 0 1-1.63-2.49Zm1.82-.51c.29.75.59 1.5 1.08 2.72a2.44 2.44 0 0 1-1.74-2.46Zm-23.76 17.02c-1.24-.61-1.29-1.4-1.13-2.51 1.17.58 1.07 1.42 1.13 2.51Zm25.67-15.18-1-2.49c1.43 1.06 1.43 1.06 1 2.49Zm1.44-1.33c-.12-.42-.24-.84-.42-1.5 1.16.31 1.19.81.95 1.43Zm1.89-2.09a2.5 2.5 0 0 1 .45.76c0 .1-.27.27-.42.42a3.47 3.47 0 0 1-.52-.64c-.03-.07.25-.28.49-.54Z"
            class="cls-4"
          ></path>
          <path
            d="m322 312.47-.72-.77.27-.22c.13.34.27.69.41 1Z"
            class="cls-4"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    color: {
      type: String,
      default: "#42b983",
    },
    size: {
      type: String,
      default: "40px",
    },
  },
};
</script>

<style scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  width: 100%;
}

.compass-container {
  width: 100px;
  height: 100px;
  animation: rotate 2s infinite linear;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.compass-outline {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.compass-needle {
  position: relative;
  width: 71%;
  animation: rotation 1s infinite linear;
}

.outline-circle {
  fill: #93cbd4;
  stroke: #2e2a2b;
}

.outline-dashes {
  fill: #ec9ec0;
}

.cls-2 {
  fill: #2e2a2b;
}

.cls-4 {
  fill: #ec9ec0;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
