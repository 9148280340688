<template>
  <b-container>
    <form class="login" @submit.prevent>
      <b-row align-h="center" cols-lg="2" cols-sm="1">
        <b-card title="Sign in" title-tag="h1">
          <b-form-input
            class="mb-3"
            required
            v-model="email"
            placeholder="Email"
          />
          <b-form-input
            class="mb-3"
            required
            v-model="password"
            type="password"
            placeholder="Password"
          />
          <template #footer>
            <b-button class="btn btn-secondary" @click="login">Login</b-button>
          </template>
        </b-card>
      </b-row>
    </form>
  </b-container>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login() {
      const { email, password } = this;
      this.$store.dispatch("login", { email, password }).then(() => {
        this.$router.push({ name: "races" });
      });
    },
  },
};
</script>

<style scoped>
h1 {
  margin-bottom: 30px;
}
</style>
