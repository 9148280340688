<template>
  <div class="dashboard-layout">
    <div class="row title-bar">
      <div class="col">
        <slot name="title"></slot>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardLayout",
};
</script>

<style scoped>
.title-bar {
  padding-top: 30px;
}
</style>
