<template>
  <div>
    <div class="card-body mb-0 pb-0">
      <h5>
        Rider info <a :href="riderEditLink"><i class="fa fa-edit mr-1" /></a>
      </h5>
      <table class="table table-sm table-hover">
        <tbody>
          <tr v-for="(value, attribute) in riderInfo" :key="attribute">
            <td>{{ value.verboseName }}</td>
            <td>
              {{ displayMethod(rider[attribute], value.displayRepresentation) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "RiderDetail",
  props: {
    raceShortName: { type: String, default: "SRMR2023" },
    baseURL: String,
    rider: Object,
    riderInfo: Object,
  },
  methods: {
    displayMethod(value, displayCallback) {
      if (displayCallback) {
        return displayCallback(value);
      }
      return value;
    },
  },
  computed: {
    riderEditLink() {
      return (
        this.baseURL +
        "apply/" +
        this.raceShortName +
        "/rider/" +
        this.rider.uuid +
        "/admin"
      );
    },
  },
};
</script>

<style scoped>
.card-body {
  text-align: left;
}

.close-button {
  cursor: pointer;
}
</style>
