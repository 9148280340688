<template>
  <span>
    <b-card-text v-if="applicantName">
      {{ applicantName }} document uploads
    </b-card-text>
    <table class="table table-sm">
      <tbody>
        <DocumentUpload
          v-for="document in documentUploads"
          :key="document.uuid"
          :event="document"
        />
      </tbody>
    </table>
  </span>
</template>

<script>
import DocumentUpload from "@/components/renderingComponents/DocumentUpload";
export default {
  name: "DocumentUploads",
  components: { DocumentUpload },
  props: {
    applicantName: {
      type: String,
    },
    documentUploads: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  computed: {},
};
</script>

<style scoped>
.card-body {
  text-align: left;
}

.btn {
  border-radius: 100px;
  box-shadow: none;
}

.disabled {
  cursor: default;
}
</style>
