<template>
  <div>
    <b-badge :variant="badgeColor">
      {{ badgeDisplayText }}
    </b-badge>
  </div>
</template>

<script>
export default {
  name: "StateBadge",
  props: {
    states: {
      type: Object,
    },
    stateKey: {
      type: String,
    },
    badgeText: {
      type: String,
    },
    overrideColor: String,
  },
  computed: {
    badgeDisplayText() {
      if (this.badgeText) {
        return this.badgeText;
      }
      try {
        return this.states[this.stateKey].verboseName;
      } catch {
        return this.stateKey;
      }
    },
    badgeColor() {
      if (this.overrideColor) {
        return this.overrideColor;
      } else if (this.states[this.stateKey].color) {
        return this.states[this.stateKey].color;
      }
      return "secondary";
    },
  },
};
</script>
