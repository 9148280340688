import { penceToPoundString } from "@/common-functions.js";

let applicantData = {
  applicantTriggers: {
    add_note: {
      moduleComponent: "requires-further-attention",
      verboseName: "Add Note",
      eventName: "Note",
      icon: {
        symbol: "fas fa-sticky-note",
        color: "#42B983",
      },
      userTrigger: false,
    },
    accept: {
      moduleComponent: "requires-further-attention",
      verboseName: "Accept",
      eventName: "Accepted",
      colorVariant: "success",
      icon: {
        symbol: "fas fa-sticky-note",
        color: "#42B983",
      },
      userTrigger: true,
    },
    off_waitlist: {
      moduleComponent: "requires-further-attention",
      verboseName: "Off Waitlist",
      eventName: "Off Waitlist",
      colorVariant: "success",
      icon: {
        symbol: "fas fa-sticky-note",
        color: "#42B983",
      },
      userTrigger: true,
    },
    waitlist: {
      moduleComponent: "requires-further-attention",
      verboseName: "Add to Waitlist",
      eventName: "Waitlisted",
      colorVariant: "success",
      icon: {
        symbol: "fas fa-sticky-note",
        color: "#42B983",
      },
      userTrigger: true,
    },
    accept_with_discount: {
      moduleComponent: "requires-further-attention",
      verboseName: "Accept with discount",
      eventName: "Accepted",
      colorVariant: "primary",
      icon: {
        symbol: "fas fa-sticky-note",
        color: "#42B983",
      },
      userTrigger: true,
    },
    reject: {
      moduleComponent: "requires-further-attention",
      verboseName: "Reject",
      eventName: "Rejected",
      colorVariant: "danger",
      icon: {
        symbol: "fas fa-sticky-note",
        color: "#42B983",
      },
      userTrigger: true,
    },
    void_invoice: {
      moduleComponent: "requires-further-attention",
      verboseName: "Void Invoice",
      eventName: "Invoice Voided",
      colorVariant: "danger",
      icon: {
        symbol: "fas fa-sticky-note",
        color: "#42B983",
      },
      userTrigger: true,
    },
    unsubmit: {
      moduleComponent: "requires-further-attention",
      verboseName: "Unsubmit",
      eventName: "Unsubmit Applicant",
      colorVariant: "danger",
      icon: {
        symbol: "fas fa-sticky-note",
        color: "#42B983",
      },
      userTrigger: true,
    },
    does_not_start: {
      moduleComponent: "requires-further-attention",
      verboseName: "DNS",
      eventName: "DNS",
      colorVariant: "danger",
      icon: {
        symbol: "fas fa-sticky-note",
        color: "#42B983",
      },
      userTrigger: true,
    },
    mark_as_paid: {
      moduleComponent: "requires-further-attention",
      verboseName: "Mark as paid",
      eventName: "Mark Paid",
      colorVariant: "danger",
      icon: {
        symbol: "fas fa-sticky-note",
        color: "#42B983",
      },
      userTrigger: true,
    },
  },
  applicantStates: {
    dns: {
      rawValue: "dns",
      verboseName: "DNS",
      color: "secondary",
    },
    created: {
      rawValue: "created",
      verboseName: "Created",
      color: "secondary",
    },
    submitted: {
      rawValue: "submitted",
      verboseName: "Submitted",
      color: "secondary",
    },
    rejected: {
      rawValue: "rejected",
      verboseName: "Rejected",
      color: "danger",
    },
    invoiced: {
      rawValue: "invoiced",
      verboseName: "Invoiced",
      color: "danger",
    },
    paid: { rawValue: "paid", verboseName: "Paid", color: "success" },
    portal_invited: { rawValue: "portal_invited", verboseName: "Portal Outstanding", color: "success" },
    portal_completed: { rawValue: "portal_completed", verboseName: "Portal Completed", color: "success" },
    registration_completed: { rawValue: "registration_completed", verboseName: "Registration Completed", color: "success" },

  },
  ApplicantInfo: {
    email: {
      verboseName: "Email",
    },
    balance: {
      verboseName: "Balance",
      displayRepresentation: penceToPoundString,
    },
    country: { verboseName: "Country" },
    own_tracker: {
      verboseName: "Own tracker",
      displayRepresentation: function (ownTracker) {
        return ownTracker ? "Yes" : "No";
      },
    },
    wave: {
      verboseName: "Wave",
      displayRepresentation: function (wave) {
        return wave ? wave.name : "";
      },
    },
    gender: {
      verboseName: "Gender",
      displayRepresentation: function (gender) {
        return gender == "F" ? "Female" : "Male";
      },
    },
  },
};

export { applicantData };
