<template>
  <Loading v-if="loading" size="100px" />
  <DashboardLayout v-else>
    <template v-slot:title>
      <b-container class="bv-example-row">
        <b-row>
          <b-col>
            <h1 class="text-left">Users</h1>
          </b-col>
          <b-col>
            <b-button variant="primary" v-b-modal.modal-1
              >Create New User</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-slot:body>
      <b-modal id="modal-1" title="Create New User" hide-footer>
        <b-form @submit.prevent="onSubmit">
          <b-form-group>
            <b-form-input
              id="input-1"
              v-model="form.email"
              type="email"
              placeholder="Email"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <b-form-input
              id="input-2"
              v-model="form.first_name"
              placeholder="First Name"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-form-input
              id="input-2"
              v-model="form.last_name"
              placeholder="Last Name"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-form-select
              id="input-3"
              v-model="form.control_point"
              :options="control_point_options"
              placeholder="Selected Control Point"
            ></b-form-select>
          </b-form-group>

          <b-button variant="primary" type="submit"> Create User </b-button>
        </b-form>
      </b-modal>
      <b-table
        striped
        hover
        :items="$store.state.usersStore.users"
        :fields="fields"
      >
        <template #cell(actions)="row"> </template>
      </b-table>
    </template>
  </DashboardLayout>
</template>

<script>
import Loading from "@/components/renderingComponents/Loading";
import DashboardLayout from "@/components/layoutComponents/DashboardLayout";
export default {
  name: "UsersPage",
  components: {
    DashboardLayout,
    Loading,
  },
  data() {
    return {
      form: { first_name: "", last_name: "", email: "", control_point: null },
      control_point_options: [{ value: null, text: "Selected ControlPoint" }],
      users: [],
      loading: false,
      fields: [
        { key: "full_name" },
        { key: "is_active", label: "Active" },
        { key: "preferred_control_point", label: "CP" },
        { key: "actions", label: "" },
      ],
    };
  },
  computed: {},
  mounted() {
    this.$root.$on("bv::modal::hidden", (bvEvent, modalId) => {
      this.form = { first_name: "", last_name: "", email: "" };
    });
    this.$store
      .dispatch(
        "arrivalsStore/getControlPoints",
        this.$store.state.racesStore.selectedRace.uuid
      )
      .then((res) => {
        res.forEach((x) =>
          this.control_point_options.push({
            value: x.uuid,
            text: x.abbreviation,
          })
        );
      });
  },
  methods: {
    onSubmit() {
      this.$store.dispatch("usersStore/createUser", this.form).then((res) => {
        this.$store.commit("usersStore/addUser", res.data);
        this.$bvModal.hide("modal-1");
      });
    },
  },
};
</script>
