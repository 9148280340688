let moduleData = {
  modules: {
    apply: { page_route: "/applications", menu_name: "Applications" },
    register: { page_route: "/riders", menu_name: "Riders" },
    arrivals: { page_route: "/arrivals", menu_name: "Arrivals" },
    track: { page_route: "/trackers", menu_name: "Trackers" },
  },
  staff_modules: {
    users: { page_route: "/users", menu_name: "Users" },
  },
};

export { moduleData };
