<template>
  <Loading v-if="loading" size="100px" />
  <ResponsivePanelsLayout v-else>
    <template v-slot:leftPanelSticky>
      <LiveSearchBar
        @searchTerm="filterRiders"
        placeholderText="Search riders"
        @hitEnter="selectRider(filteredRiders[0])"
      />
    </template>

    <template v-slot:leftPanelScroll>
      <SelectedRider
        v-if="selectedRider"
        :rider="selectedRider"
        :allRiderTriggers="allRiderTriggers"
        :riderStates="riderStates"
        :riderInfo="riderInfo"
        :trackers="trackers"
        :baseURL="baseURL"
        @refresh-rider="getRiderDetail"
        @clearRider="selectRider"
      />
    </template>

    <template v-slot:rightPanel>
      <List
        :cards="filteredRiders"
        headerField="searchString"
        @select-detail="selectRider"
      >
        <template v-slot:header-badges="{ card }">
          <TagBadge
            class="col col-auto px-1"
            v-if="card.pair"
            fa-icon="p"
            color="info"
          />
          <TagBadge
            class="col col-auto px-1"
            v-if="card.rider_agreement"
            fa-icon="signature"
            color="primary"
          />
          <ToolTip
            v-if="card.green_leaderboard"
            toolTipText="Green Leaderboard"
          >
            <TagBadge
              class="col col-auto px-1"
              fa-icon="leaf"
              color="success"
            />
          </ToolTip>
          <ToolTip
            v-if="card.requires_attention"
            toolTipText="Requires Attention!"
          >
            <TagBadge class="col col-auto px-1" fa-icon="flag" color="danger" />
          </ToolTip>
          <ToolTip v-if="card.media_license" toolTipText="Media license">
            <TagBadge
              class="col col-auto px-1"
              v-if="card.media_license"
              fa-icon="camera"
              color="info"
            />
          </ToolTip>
          <ToolTip v-if="card.own_tracker" toolTipText="Preassigned Tracker">
            <TagBadge
              class="col col-auto px-1"
              v-if="card.pre_assigned_tracker"
              badgeText="Pre-assigned"
              color="info"
            />
          </ToolTip>
          <i
            v-if="card.balance < 0"
            class="fa fa-exclamation-circle my-auto text-danger"
          />
          <TagBadge
            class="col col-auto px-1"
            :badgeText="balanceDisplayRepr(card.balance)"
          />
          <StateBadge
            class="col col-auto pl-1"
            :states="riderStates"
            :stateKey="card.state"
          />
        </template>
      </List>
    </template>
  </ResponsivePanelsLayout>
</template>

<script>
import ToolTip from "@/components/renderingComponents/ToolTip.vue";
import Loading from "@/components/renderingComponents/Loading.vue";
import LiveSearchBar from "@/components/renderingComponents/LiveSearchBar";
import List from "@/components/renderingComponents/List";
import SelectedRider from "@/components/logicComponents/SelectedRider";
import ResponsivePanelsLayout from "@/components/layoutComponents/ResponsivePanelsLayout";
import TagBadge from "@/components/renderingComponents/TagBadge.vue";
import StateBadge from "@/components/renderingComponents/StateBadge.vue";
import { penceToPoundString, fileDownload } from "@/common-functions.js";
import { riderData } from "@/reference/RiderReferenceData.js";

export default {
  name: "RidersPage",
  components: {
    Loading,
    LiveSearchBar,
    List,
    SelectedRider,
    ResponsivePanelsLayout,
    TagBadge,
    ToolTip,
    StateBadge,
  },
  data() {
    return {
      baseURL: "",
      riders: null,
      trackers: null,
      filteredRiders: [],
      selectedRider: null,
      loading: true,
      message: "",
    };
  },
  computed: {
    allRiderTriggers() {
      return riderData.allRiderTriggers;
    },
    riderStates() {
      return riderData.riderStates;
    },
    riderInfo() {
      return riderData.riderInfo;
    },
    ridersList() {
      return Object.values(this.riders);
    },
  },
  methods: {
    filterRiders(searchTerm) {
      this.selectedRider = null;
      this.filteredRiders = this.ridersList.filter((rider) =>
        rider.searchString.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    selectRider(rider) {
      if (!rider) {
        this.selectedRider = null;
        this.$router.push("/riders/").catch(() => {});
      } else if (this.selectedRider && this.selectedRider.uuid == rider.uuid) {
        this.$router.push("/riders/").catch(() => {});
      } else {
        this.$router.push("/riders/" + rider.uuid).catch(() => {});
      }
    },
    getRiderDetail(uuid) {
      this.$store
        .dispatch("ridersStore/getRiderDetail", uuid)
        .then((riderDetail) => {
          if (
            riderDetail.race != this.$store.state.racesStore.selectedRace.uuid
          ) {
            this.selectRider();
          } else this.selectedRider = riderDetail;
          this.filteredRiders = this.ridersList;
        })
        .catch((err) => {
          console.error(err);
          this.selectRider();
        });
    },
    balanceDisplayRepr(balance) {
      return penceToPoundString(balance);
    },
  },
  created() {
    this.baseURL = this.$store.state.baseURL;
    this.$store
      .dispatch(
        "ridersStore/getAllRiders",
        this.$store.state.racesStore.selectedRace.uuid
      )
      .then((riders) => {
        this.riders = riders;
        this.filteredRiders = this.ridersList;
        if (this.$route.params) {
          if (!this.$route.params.uuid) {
            this.selectRider();
          } else {
            this.getRiderDetail(this.$route.params.uuid);
          }
        }
        this.loading = false;
      })
      .catch((error) => console.error(error));
    this.$store
      .dispatch(
        "trackersStore/getAllTrackers",
        this.$store.state.racesStore.selectedRace.uuid
      )
      .then((trackers) => {
        this.trackers = trackers;
      })
      .catch((error) => {
        console.error(error);
      });
    this.$watch(
      () => this.$route.params,
      (uuidPath) => {
        if (!uuidPath.uuid) {
          this.selectRider();
        } else {
          this.getRiderDetail(uuidPath.uuid);
        }
      }
    );
  },
};
</script>
