let listToDict = (arr) => {
  let dictionary = arr.reduce((a, x) => ({ ...a, [x.uuid]: x }), {});
  return dictionary;
};

function byField(fieldname, arr, fieldvalue) {
  let item = arr.filter((obj) => obj[fieldname] == fieldvalue)[0];
  if (item) {
    return item;
  }
  return null;
}

function byUUID(arr, uuid) {
  return byField("uuid", arr, uuid);
}

function toPositionString(position) {
  let append = "th";
  let positionString = position.toString();
  if (positionString.substr(-1) == "1" && positionString.substr(-2) != "11") {
    append = "st";
  } else if (
    positionString.substr(-1) == "2" &&
    positionString.substr(-2) != "12"
  ) {
    append = "nd";
  } else if (
    positionString.substr(-1) == "3" &&
    positionString.substr(-2) != "13"
  ) {
    append = "rd";
  }
  return positionString + append;
}

function toCapitalisedString(str) {
  return str.replace(/^\w/, (c) => c.toUpperCase());
}

function positivePenceToPoundString(amountInPence) {
  let amountStr = String(amountInPence);
  if (amountStr.length < 3) {
    amountStr = "000".slice(0, 3 - amountStr.length) + amountStr;
  }
  return (
    "£" + amountStr.slice(0, amountStr.length - 2) + "." + amountStr.slice(-2)
  );
}

function penceToPoundString(amountInPence) {
  if (amountInPence < 0) {
    let poundString = positivePenceToPoundString(amountInPence * -1);
    return "-" + poundString;
  }
  return positivePenceToPoundString(amountInPence);
}

function retrieveItemByUUID(store, itemType, uuid) {
  if (itemType == "rider") {
    return store.state.ridersStore.riders[uuid];
  } else if (itemType == "tracker") {
    return store.state.trackersStore.trackers[uuid];
  }
  return null;
}
function fileDownload(response, filename) {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
}

export {
  listToDict,
  byField,
  byUUID,
  toPositionString,
  toCapitalisedString,
  penceToPoundString,
  retrieveItemByUUID,
  fileDownload,
};
