<template>
  <div :class="formGroupClass">
    <label v-if="displayLabel" :for="fieldName">{{ fieldName }}</label>
    <input
      v-else
      type="checkbox"
      :class="inputClass"
      :id="fieldName"
      :aria-label="ariaDescription"
      :disabled="disabled"
      v-model="input"
    />
    <small v-if="fieldDescription" class="form-text">{{
      fieldDescription
    }}</small>
  </div>
</template>

<script>
export default {
  name: "CheckboxField",
  props: {
    fieldName: {
      type: String,
      required: true, // required for aria accessibility
    },
    fieldDescription: String,
    displayLabel: {
      type: Boolean,
      default: false, // in case we don't want to have the required fieldName displayed
    },
    inputClass: {
      type: String,
      default: "form-check-input", // for changing size
    },
    formGroupClass: {
      type: String,
      default: "form-check text-left mb-3", // for changing field layouts, defaults to vertical stack of label, input, description
    },
    value: Boolean,
    disabled: Boolean,
  },
  computed: {
    ariaDescription() {
      return this.fieldDescription ? this.fieldDescription : this.fieldName;
    },
    input: {
      get() {
        return this.value;
      },
      set(inputValue) {
        this.$emit("input", inputValue);
      },
    },
  },
  // methods: {
  //   updateValue() {
  //     this.checked = !this.checked
  //     this.$emit('input', this.checked)
  //   }
  // },
};
</script>

<style scoped></style>
