import axios from "axios";
import { listToDict } from "../common-functions";

const augmentRiders = (riders) => {
  Object.keys(riders).map((uuid) => {
    let rider = riders[uuid];
    rider.fullName = rider.first_name + " " + rider.last_name;
    let cap_string = rider.cap_string == "None" ? "" : " #" + rider.cap_string;
    rider.searchString = rider.fullName + cap_string;
  });
  return riders;
};

const state = () => ({
  riders: null,
});

const mutations = {
  setRiders(state, riders) {
    state.riders = riders;
  },
  setRider(state, rider) {
    state.riders[rider.uuid] = rider;
  },
};

const actions = {
  getDocumentUploadURL({ rootState }, documentUploadUUID) {
    let url =
      rootState.baseURL +
      "api/v1/document-upload/" +
      documentUploadUUID +
      "/url";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  exportRiders({ rootState }, raceUUID) {
    let url =
      rootState.baseURL +
      rootState.apiRoutes.races +
      raceUUID +
      "/riders/export/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
        responseType: "arraybuffer",
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  triggerDocumentUpload(
    { rootState },
    { documentUploadUUID, trigger, additional_data }
  ) {
    let url =
      rootState.baseURL +
      "api/v1/document-upload/" +
      documentUploadUUID +
      "/triggers/" +
      trigger +
      "/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "POST",
        data: additional_data ? additional_data : {},
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getRiderDetail({ commit, rootState }, riderUUID) {
    let url =
      rootState.baseURL + rootState.apiRoutes.riderDetail + riderUUID + "/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          let rider = resp.data;
          rider.fullName = rider.first_name + " " + rider.last_name;
          let cap_string =
            rider.cap_string == "None" ? "" : " #" + rider.cap_string;
          rider.searchString = rider.fullName + cap_string;
          for (let event of rider.history) {
            event.eventType = "event";
          }
          for (let transaction of rider.transactions) {
            transaction.eventType = "transaction";
            transaction.parentUUID = rider.uuid;
          }
          for (let arrival of rider.arrivals) {
            arrival.eventType = "arrival";
          }
          for (let assignment of rider.tracker_history) {
            assignment.eventType = "assignment";
          }
          commit("setRider", rider);
          resolve(rider);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllRiders({ commit, rootState }, raceUUID) {
    let url = rootState.baseURL + "api/v1/races/" + raceUUID + "/riders/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          let data = resp.data;
          let riders = augmentRiders(listToDict(data));
          commit("setRiders", riders);
          resolve(riders);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  submitRiderTrigger({ rootState }, { riderUUID, trigger, data }) {
    let url =
      rootState.baseURL +
      rootState.apiRoutes.riderDetail +
      riderUUID +
      "/triggers/" +
      trigger +
      "/";
    return new Promise((resolve, reject) => {
      axios({
        url: url,
        method: "POST",
        data: data,
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default { namespaced: true, state, mutations, actions };
