<template>
  <div class="card border-light mb-1">
    <div class="card-body p-2">
      <div class="row">
        <div class="col-2 mb-auto p-2 text-center">
          <i :class="iconSymbol" :style="{ color: iconColor }"></i>
        </div>

        <div class="col mb-auto pt-2">
          <div class="row">
            <div class="col">
              {{ transactionName }}
            </div>
            <div class="col col-auto">
              {{ amountInPounds }}
            </div>
          </div>
          <div class="row mt-3 small text-muted" v-if="transaction.notes">
            <div class="col">
              {{ transaction.notes }}
            </div>
          </div>
        </div>

        <div class="col col-auto text-right mb-auto">
          <slot name="time">
            <div class="small text-muted">{{ transactionTime[0] }}</div>
            <div class="small text-muted">{{ transactionTime[1] }}</div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { penceToPoundString } from "@/common-functions.js";

export default {
  name: "ItemTimelineTransaction",
  props: {
    event: Object,
  },
  computed: {
    transaction() {
      return this.event;
    },
    isDebit() {
      if (this.transaction.debit_wallet == null) {
        return false;
      }
      return this.transaction.debit_wallet.uuid == this.transaction.parentUUID;
    },
    iconSymbol() {
      return "fas fa-pound-sign";
    },
    iconColor() {
      return this.isDebit ? "#e75a7c" : "#42B983";
    },
    amountInPounds() {
      let sign = this.isDebit ? "-" : "";
      return sign + penceToPoundString(this.transaction.amount_in_pence);
    },
    transactionName() {
      return this.isDebit ? "Debit" : "Credit";
    },
    transactionTime() {
      return [
        new Date(this.transaction.datetime).toLocaleDateString("en-gb"),
        new Date(this.transaction.datetime).toLocaleTimeString("en-gb"),
      ];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
