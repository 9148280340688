<template>
  <div id="app">
    <div id="page">
      <NavBar
        v-if="!$route.meta.hideNav"
        :fullName="
          $store.state.usersStore.me ? $store.state.usersStore.me.full_name : ''
        "
        :selectedRace="
          $store.state.racesStore.selectedRace
            ? $store.state.racesStore.selectedRace.short_name
            : ''
        "
        :availableModules="$store.state.usersStore.availableModules"
      />
      <router-view id="page-view" class="container pt-0" />
      <MessageBar :messages="$store.state.messages" />
    </div>
  </div>
</template>

<style scoped>
#page-view {
  margin-top: 100px;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<script>
import NavBar from "@/components/renderingComponents/NavBar.vue";
import MessageBar from "@/components/renderingComponents/MessageBar.vue";

export default {
  name: "App",
  beforeCreate() {
    if (this.$store.state.auth.logged_in) {
      this.$store.dispatch("usersStore/getMe").then((me) => {
        this.$store
          .dispatch("usersStore/getAllUsers", me.organisation)
          .then((users) => {
            this.$store.commit("usersStore/setUsers", users);
          });
        this.$store.commit("usersStore/setMe", me);
        this.$store.commit(
          "usersStore/setAvailableModules",
          me.available_modules
        );
        this.$store.commit("racesStore/setAvailableRaces", me.races);
        if (!this.$store.state.racesStore.selectedRace) {
          this.$store.commit("racesStore/setSelectedRace", me.races[0]);
        }
      });
    }
  },
  components: {
    NavBar,
    MessageBar,
  },
};
</script>
