<template>
  <b-form @submit.prevent="onSubmit('submit')" class="text-left">
    <b-form-group
      v-for="(field, index) in fieldsData"
      :id="`form-group-${index}`"
      :key="index"
      :description="field.help_text"
      :label="field.label"
      :label-for="`form-input-${index}`"
    >
      <component
        :is="getFormComponent(field.type)"
        :id="`form-input-${index}`"
        :state="validateRequired(field)"
        :required="field.is_required"
        :disabled="field.is_readonly"
        :placeholder="field.placeholder"
        v-model="field.value"
        :type="field.type"
        :options="field.options"
        text-field="label"
        trim
        :rows="field.type === 'textarea' ? 4 : null"
      />
    </b-form-group>

    <b-button @click="formDirty = true" type="submit" variant="primary">{{
      submitButtonName
    }}</b-button>
    <b-button
      v-if="$listeners.save"
      @click="(formDirty = false), onSubmit('save')"
      type="button"
      variant="secondary"
      class="ml-2"
      >{{ saveButtonName }}</b-button
    >
  </b-form>
</template>

<script>
const formItemMap = {
  "b-form-input": ["text", "email", "number", "date"],
  "b-form-select": ["select"],
  "b-form-textarea": ["textarea"],
};

function addValueKeys(fields) {
  return fields.map((obj) => {
    return { ...obj, value: obj.value ? obj.value : "" };
  });
}

export default {
  name: "FormBuilder",
  props: {
    submitButtonName: { type: String, default: "Submit" },
    saveButtonName: { type: String, default: "Save" },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fieldsData: [],
      formDirty: false,
    };
  },
  watch: {
    fields: {
      handler() {
        this.fieldsData = addValueKeys(this.fields);
      },
      deep: true,
    },
  },
  mounted() {
    this.fieldsData = addValueKeys(this.fields);
  },
  computed: {
    fieldValidation() {
      return this.fieldsData.map((field) => {
        return {
          key: field.key,
          value: this.validateRequired(field),
        };
      });
    },
  },
  methods: {
    validateRequired(field) {
      const { is_required, value } = field;
      if (!is_required || !this.formDirty) return null;
      return !!value && value.length > 0;
    },
    onSubmit(eventName) {
      let dataToSubmit = {};
      this.fieldsData
        .filter((field) => !field.is_readonly)
        .map((field) => (dataToSubmit[field.key] = field.value));
      this.$emit(eventName, dataToSubmit);
    },
    getFormComponent(type) {
      for (const [component, types] of Object.entries(formItemMap)) {
        if (types.includes(type)) {
          return component;
        }
      }
      return "b-form-input";
    },
  },
};
</script>
