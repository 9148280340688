<template>
  <ApplyFlowLayout :race="selectedRace">
    <template v-slot:body>
      <Loading v-if="loading" />
      <GetApplyRace @race-selected="selectRace" @error="redirect404" />
      <b-card-sub-title class="mb-2 mt-3"
        ><strong>Email:</strong> {{ applicant.email }}</b-card-sub-title
      >
      <b-card-sub-title class="mb-2"
        ><strong>Category:</strong> {{ applicant.category }}</b-card-sub-title
      >
      <b-card-sub-title class="mb-2" v-if="applicant.pair_email"
        ><strong>Pair Email:</strong>
        {{ applicant.pair_email }}</b-card-sub-title
      >
      <b-card-sub-title
        class="my-4 h5"
        v-if="selectedRace.details_guidance"
        v-html="selectedRace.details_guidance[applicant.default_language]"
      />
      <FormBuilder
        :fields="detailFormFields"
        @submit="submitDetails"
        submitButtonName="Save and continue"
      />
    </template>
  </ApplyFlowLayout>
</template>

<script>
import ApplyFlowLayout from "@/components/layoutComponents/ApplyFlowLayout.vue";
import Loading from "@/components/renderingComponents/Loading.vue";
import GetApplyRace from "@/components/logicComponents/GetApplyRace.vue";
import FormBuilder from "@/components/renderingComponents/FormBuilder.vue";

export default {
  name: "ApplyDetails",
  components: {
    FormBuilder,
    ApplyFlowLayout,
    GetApplyRace,
    Loading,
  },
  data() {
    return {
      loading: true,
      applicant: null,
      detailFormFields: [],
      selectedRace: Object(),
    };
  },
  mounted() {
    this.$store
      .dispatch("applyStore/getApplicantV2", this.$route.params.uuid)
      .then((res) => {
        this.loading = false;
        this.applicant = res;
        if (this.applicant.state == "submitted") {
          this.$router.push({
            name: "applyThankYou",
            params: {
              raceShortName: this.applicant.race_short_name,
            },
            query: {
              language: this.applicant.default_language,
            },
          });
        }

        if (this.applicant.state == "checking_out") {
          window.location.href = this.applicant.checkout_url;
        }
        this.$store
          .dispatch("applyStore/getRaceDetailQuestions", {
            race: this.applicant.race,
            language: this.applicant.default_language,
          })
          .then(
            (res) => (
              (this.loading = false),
              (this.detailFormFields = res),
              this.buildValues()
            )
          );
      });
  },
  methods: {
    redirect404() {
      this.$router.push({ name: "notFound" });
    },
    selectRace(race) {
      this.loading = false;
      this.selectedRace = race;
    },
    buildValues() {
      this.detailFormFields.forEach(
        (formField) => (formField.value = this.applicant[formField.key])
      );
    },
    submitDetails(data) {
      (this.loading = true),
        this.$store
          .dispatch("applyStore/patchApplicantV2", {
            UUID: this.applicant.uuid,
            data: data,
          })
          .then((res) => {
            if (res.next_page == "questions") {
              this.$router.push({
                name: "applyQuestions",
                params: {
                  uuid: this.applicant.uuid,
                  raceShortName: this.selectedRace.short_name,
                },
                query: { token: this.$route.query.token },
              });
            } else if (res.next_page == "thank_you") {
              this.$router.push({
                name: "applyThankYou",
                params: { raceShortName: this.selectedRace.short_name },
                query: {
                  language: this.applicant.default_language,
                  token: this.$route.query.token,
                },
              });
            } else if (res.next_page == "checkout") {
              window.location.href = res.checkout_url;
            } else if (res.next_page == "sold_out") {
              this.$router.push({
                name: "soldOut",
                query: { token: this.$route.query.token },
              });
            }
          });
    },
  },
};
</script>
