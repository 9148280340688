<template>
  <Loading v-if="loading" size="100px" />
  <DashboardLayout v-else>
    <template v-slot:title>
      <h1 class="text-left">
        {{
          $store.state.racesStore.selectedRace
            ? $store.state.racesStore.selectedRace.short_name
            : ""
        }}
      </h1>
      <hr class="mt-0" />
    </template>

    <template v-slot:body>
      <SelectRace :races="availableRaces" @select-race="selectRace" />
      <div class="card-deck" v-if="!loading">
        <DashboardCard
          cardTitle="Riders"
          :numberOfItems="numberOfRiders"
          :itemStates="riderStates"
          :referenceStates="riderRefData"
        >
          <template slot="icon">
            <i class="fa fa-biking mr-1" />
          </template>
        </DashboardCard>

        <DashboardCard
          cardTitle="Trackers"
          :numberOfItems="numberOfTrackers"
          :itemStates="trackerStates"
          :referenceStates="trackerRefData"
        >
          <template slot="icon">
            <i class="fa fa-satellite mr-1" />
          </template>
        </DashboardCard>
        <DashboardCard
          cardTitle="Applicants"
          :numberOfItems="numberOfApplicants"
          :itemStates="applicantStates"
          :referenceStates="applicantRefData"
        >
          <template slot="icon">
            <i class="fa fa-envelope mr-1" />
          </template>
        </DashboardCard>
      </div>
    </template>
  </DashboardLayout>
</template>

<script>
import Loading from "@/components/renderingComponents/Loading";
import DashboardLayout from "@/components/layoutComponents/DashboardLayout";
import DashboardCard from "@/components/renderingComponents/DashboardCard";
import SelectRace from "@/components/renderingComponents/SelectRace";
import { riderData } from "@/reference/RiderReferenceData.js";
import { trackerData } from "@/reference/TrackerReferenceData.js";
import { applicantData } from "@/reference/ApplicantReferenceData.js";

export default {
  name: "RacePage",
  components: {
    Loading,
    DashboardLayout,
    DashboardCard,
    SelectRace,
  },
  data() {
    return {
      loading: false,
      riderStates: Object(),
      trackerStates: Object(),
      applicantStates: Object(),
      numberOfTrackers: 0,
      numberOfRiders: 0,
      numberOfApplicants: 0,
    };
  },
  computed: {
    availableRaces() {
      return this.$store.state.racesStore.availableRaces;
    },
    trackerRefData() {
      return trackerData.trackerStates;
    },
    riderRefData() {
      return riderData.riderStates;
    },
    applicantRefData() {
      return applicantData.applicantStates;
    },
  },
  methods: {
    selectRace(race) {
      this.$store.commit("racesStore/setSelectedRace", race);
    },
    setRidersTrackers(selectedRace) {
      this.selectedRace = selectedRace;
      this.riderStates = selectedRace.rider_states;
      this.trackerStates = selectedRace.tracker_states;
      this.numberOfRiders = selectedRace.total_riders;
      this.numberOfTrackers = selectedRace.total_trackers;
      this.numberOfApplicants = selectedRace.total_applicants;
      this.applicantStates = selectedRace.applicant_states;
    },
  },
  created() {
    this.$store.dispatch("usersStore/getMe").then((user) => {
      this.$store.commit("usersStore/setMe", user);
      this.$store.commit("racesStore/setAvailableRaces", user.races);
      if (!this.$store.state.racesStore.selectedRace) {
        this.$store.commit("racesStore/setSelectedRace", user.races[0]);
      }
      this.setRidersTrackers(user.races[0]);
    });

    this.$watch(
      () => this.$store.state.racesStore.selectedRace,
      (selectedRace) => {
        this.setRidersTrackers(selectedRace);
      }
    );
  },
};
</script>
