<template>
  <b-container>
    <h3>Sorry, page not found</h3>
    <img width="300" src="@/assets/404nod.svg" />
  </b-container>
</template>

<script>
export default {
  name: "PageNotFound",
  data() {},
  methods: {},
};
</script>

<style scoped>
img {
  max-width: 80%;
}
h1 {
  margin-bottom: 30px;
}
</style>
