let trackerData = {
  allTrackerTriggers: {
    unpre_assign: {
      formComponent: "trigger-with-volunteer",
      verboseName: "Unpre-assign",
      eventName: "Unpre-assigned",
      icon: {
        symbol: "fas fa-check",
        color: "#42B983",
      },
      userTrigger: true,
    },
    pre_assign: {
      formComponent: "give-tracker",
      verboseName: "Pre-assign",
      eventName: "Pre-assigned",
      icon: {
        symbol: "fas fa-check",
        color: "#42B983",
      },
      userTrigger: true,
    },
    make_unavailable: {
      formComponent: "trigger-with-volunteer",
      verboseName: "Make Unavailable",
      eventName: "Made Unavailable",
      icon: {
        symbol: "fas fa-sign-out-alt",
        color: "#5c95ff",
      },
      userTrigger: true,
    },
    make_available: {
      formComponent: "trigger-with-volunteer",
      verboseName: "Make Available",
      eventName: "Made Available",
      icon: {
        symbol: "fas fa-sign-out-alt",
        color: "#5c95ff",
      },
      userTrigger: true,
    },
    give_tracker: {
      formComponent: "give-tracker",
      verboseName: "Give to rider",
      eventName: "Given to rider",
      icon: {
        symbol: "fas fa-sign-out-alt",
        color: "#5c95ff",
      },
      userTrigger: true,
    },
    retrieve_tracker: {
      formComponent: "retrieve-tracker",
      verboseName: "Retrieve from rider",
      eventName: "Retrieved from rider",
      icon: {
        symbol: "fas fa-sign-in-alt",
        color: "#5c95ff",
      },
      userTrigger: true,
    },
    lose_tracker: {
      formComponent: "trigger-with-volunteer",
      verboseName: "Record as lost",
      eventName: "Tracker lost",
      icon: {
        symbol: "fas fa-ban",
        color: "#e75a7c",
      },
      userTrigger: true,
    },
  },
  trackerStates: {
    with_rider: { verboseName: "With rider", color: "primary" },
    available: { verboseName: "Available", color: "success" },
    unavailable: { verboseName: "Unavailable", color: "danger" },
  },
  trackerInfo: {
    esn_number: { verboseName: "ESN" },
    tcr_id: { verboseName: "TCR ID" },
    purchase_date: { verboseName: "Purchase date" },
    warranty_expiry: { verboseName: "Warranty expiry date" },
  },
};

export { trackerData };
