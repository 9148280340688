<template>
  <router-link :to="{ path: itemURL }">
    <i :class="itemIconClass" />
    {{ itemDisplayValue }}
  </router-link>
</template>

<script>
export default {
  name: "LinkedItem",
  props: {
    itemType: String,
    itemObject: Object,
  },
  data() {
    return {
      itemMapping: {
        rider: {
          icon: "fa fa-biking mr-1",
          displayFields: ["first_name", "last_name"],
          urlRoot: "riders",
        },
        tracker: {
          icon: "fa fa-satellite-dish mr-1",
          displayFields: ["tcr_id"],
          urlRoot: "trackers",
        },
      },
    };
  },
  computed: {
    itemIconClass() {
      return this.itemMapping[this.itemType].icon;
    },
    itemDisplayValue() {
      let displayString = "";
      this.itemMapping[this.itemType].displayFields.forEach(
        (field) =>
          (displayString = displayString.concat(this.itemObject[field], " "))
      );
      return displayString;
    },
    itemURL() {
      return (
        "/" +
        this.itemMapping[this.itemType].urlRoot +
        "/" +
        this.itemObject.uuid
      );
    },
  },
};
</script>

<style scoped></style>
