<template>
  <tr>
    <td>{{ document_upload.name }}</td>
    <td>
      <TagBadge
        class="col col-auto px-1"
        :badgeText="document_upload.state"
        :color="eventColour(document_upload.state)"
      />
    </td>
    <td>
      <a v-if="showDocument" @click="openDocument"
        ><i class="fa fa-eye text-primary mr-1"></i
      ></a>
      <i
        v-if="error"
        class="fa fa-exclamation-triangle mr-1"
        style="color: red"
      ></i>
    </td>
    <td>
      <p>{{ document_upload.rejection_reason }}</p>
    </td>
    <td v-if="document_upload.state == 'uploaded'">
      <a @click="acceptDocument"
        ><i class="fa fa-check mr-1" style="color: green"></i
      ></a>
    </td>
    <td v-if="document_upload.state == 'uploaded'">
      <a @click="toggleRejectionReason"
        ><i class="fa fa-ban mr-1" style="color: red"></i
      ></a>
    </td>
    <span v-if="showRejectionReason">
      <td>
        <input
          v-model="rejectionReason"
          placeholder="Rejection reason"
          required
        />
      </td>
      <td>
        <button class="btn btn-danger btn-sm" @click="rejectDocument">
          Reject Document
        </button>
      </td>
    </span>
  </tr>
</template>

<script>
import TagBadge from "@/components/renderingComponents/TagBadge.vue";
export default {
  name: "DocumentUpload",
  components: {
    TagBadge,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      document_upload: { ...this.event },
      error: false,
      showRejectionReason: false,
      rejectionReason: "",
    };
  },
  methods: {
    toggleRejectionReason() {
      this.showRejectionReason = !this.showRejectionReason;
    },
    eventColour(state) {
      return {
        requested: "info",
        created: "info",
        rejected: "danger",
        accepted: "success",
        uploaded: "primary",
      }[state];
    },
    openDocument() {
      this.$store
        .dispatch("ridersStore/getDocumentUploadURL", this.document_upload.uuid)
        .then((response) => {
          window.open(response.data["url"]);
        })
        .catch(() => {
          this.error = true;
        });
    },
    acceptDocument() {
      this.$store
        .dispatch("ridersStore/triggerDocumentUpload", {
          documentUploadUUID: this.document_upload.uuid,
          trigger: "accepts",
        })
        .then((resp) => {
          this.document_upload.state = resp.data.state;
        })
        .catch(() => {
          this.error = true;
        });
    },
    rejectDocument() {
      this.$store
        .dispatch("ridersStore/triggerDocumentUpload", {
          documentUploadUUID: this.document_upload.uuid,
          trigger: "rejects",
          additional_data: { rejection_reason: this.rejectionReason },
        })
        .then((resp) => {
          this.document_upload.state = resp.data.state;
        })
        .catch(() => {
          this.error = true;
        });
    },
  },
  computed: {
    showDocument() {
      if (
        !(
          this.document_upload.state == "created" ||
          this.document_upload.state == "requested"
        ) &&
        !this.error
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
i {
  cursor: pointer;
}
</style>
