<template>
  <div class="card">
    <SelectedItemHeader
      :cardHeader="rider.searchString"
      @clear="$emit('clearRider')"
    />

    <ItemActions
      :itemTriggers="riderTriggers"
      :allTriggers="allRiderTriggers"
      @select-trigger="selectTrigger"
      :selectedTrigger="selectedTrigger"
    />

    <!-- using a dynamic component here, allows us to pass in a different component if more data is needed to be sent -->
    <component
      @form-submission="submitTrigger"
      @tracker-form-submission="submitTrackerTrigger"
      v-if="selectedTrigger"
      :is="componentMap[selectedTrigger.formComponent].component"
      v-bind="componentMap[selectedTrigger.formComponent].props"
      :loading="formSubmitting"
      :formTitle="selectedTrigger.verboseName"
      :item="rider"
      itemType="Rider"
      displayFieldName="fullName"
    />

    <div class="card-body">
      <div class="row">
        <StateBadge
          class="col col-auto mb-1"
          :states="riderStates"
          :stateKey="rider.state"
        />
        <LinkedItem
          v-if="rider.current_tracker"
          class="col col-auto"
          itemType="tracker"
          :itemObject="rider.current_tracker"
        />
        <div v-else class="col col-auto text-muted small my-auto">
          No tracker
        </div>
        <LinkedItem
          v-if="rider.pair"
          class="col col-auto"
          itemType="rider"
          :itemObject="rider.pair"
        />
        <div v-else-if="rider.pre_assigned_tracker" class="col col-auto row">
          <LinkedItem
            class="col col-auto"
            itemType="tracker"
            :itemObject="preassignedTracker"
          />
          <TagBadge
            class="col col-auto"
            badgeText="Pre-assigned"
            color="info"
          />
        </div>
      </div>
    </div>

    <RiderDetail
      :rider="rider"
      :riderInfo="riderInfo"
      :baseURL="baseURL"
      :raceShortName="raceShortName"
    />

    <EmergencyContact :emergencyContact="rider.emergency_contact" />

    <ItemTimeLine
      :sortedEvents="rider.history"
      noEventsMsg="No events for this rider"
      :allItemTriggers="allRiderTriggers"
      timelineTitle="History"
      itemType="rider"
    />

    <ItemTimeLine
      :sortedEvents="rider.transactions"
      noEventsMsg="No transactions for this rider"
      timelineTitle="Deposit account"
      itemType="rider"
    />

    <ItemTimeLine
      :sortedEvents="rider.tracker_history"
      noEventsMsg="No trackers assinged to this rider"
      timelineTitle="Trackers"
      itemType="rider"
    />
  </div>
</template>

<script>
import EmergencyContact from "@/components/renderingComponents/EmergencyContact.vue";
import RiderDetail from "@/components/renderingComponents/RiderDetail.vue";
import ItemActions from "@/components/renderingComponents/ItemActions";
import TriggerWithVolunteer from "@/components/forms/TriggerWithVolunteer";
import AddRemoveMoney from "@/components/forms/AddRemoveMoney";
import ItemTimeLine from "@/components/renderingComponents/ItemTimeline";
import SelectedItemHeader from "@/components/renderingComponents/SelectedItemHeader";
import LinkedItem from "@/components/renderingComponents/LinkedItem";
import StateBadge from "@/components/renderingComponents/StateBadge.vue";
import TagBadge from "@/components/renderingComponents/TagBadge.vue";
import GiveTracker from "@/components/forms/GiveTracker";
import RetrieveTracker from "@/components/forms/RetrieveTracker";
import RequiresFurtherAttention from "@/components/forms/RequiresFurtherAttention";

import { retrieveItemByUUID } from "@/common-functions.js";

export default {
  name: "SelectedRider",
  components: {
    EmergencyContact,
    RiderDetail,
    RequiresFurtherAttention,
    ItemActions,
    TriggerWithVolunteer,
    ItemTimeLine,
    SelectedItemHeader,
    LinkedItem,
    StateBadge,
    TagBadge,
    GiveTracker,
    RetrieveTracker,
  },
  props: {
    baseURL: String,
    rider: {
      type: Object,
    },
    allRiderTriggers: {
      type: Object,
    },
    riderStates: Object,
    riderInfo: Object,
    trackers: {
      type: Object,
    },
  },
  data() {
    return {
      selectedTrigger: null,
      formSubmitting: false,
      transactionTimelineFields: ["amount_in_pence"],
    };
  },
  computed: {
    raceShortName() {
      return this.$store.state.racesStore.selectedRace.short_name;
    },
    preassignedTracker() {
      if (this.rider.pre_assigned_tracker) {
        return retrieveItemByUUID(
          this.$store,
          "tracker",
          this.rider.pre_assigned_tracker
        );
      }
      return null;
    },
    componentMap() {
      return {
        "trigger-with-volunteer": {
          component: TriggerWithVolunteer,
        },
        "requires-further-attention": {
          component: RequiresFurtherAttention,
        },
        "add-remove-money": {
          component: AddRemoveMoney,
        },
        "give-tracker": {
          component: GiveTracker,
          props: {
            trackers: Object.values(this.trackers).filter(
              (tracker) => tracker.state == "available"
            ),
            preassignedTracker: this.preassignedTracker,
          },
        },
        "retrieve-tracker": {
          component: RetrieveTracker,
        },
      };
    },
    riderTriggers() {
      let triggers = this.rider.triggers;
      if (!this.rider.current_tracker) {
        triggers.push("give_tracker");
      } else {
        triggers.push("retrieve_tracker");
      }
      return triggers;
    },
  },
  watch: {
    rider() {
      this.selectedTrigger = null;
    },
  },
  methods: {
    selectTrigger(trigger) {
      // close trigger form if selecting same button again
      if (
        this.selectedTrigger &&
        this.selectedTrigger.trigger == trigger.trigger
      ) {
        this.selectedTrigger = null;
      } else {
        this.selectedTrigger = trigger;
      }
    },
    submitTrigger(formData) {
      this.formSubmitting = true;
      this.$store
        .dispatch("ridersStore/submitRiderTrigger", {
          riderUUID: this.rider.uuid,
          trigger: this.selectedTrigger.trigger,
          data: formData,
        })
        .then(() => {
          this.$emit("refresh-rider", this.rider.uuid);
          this.selectedTrigger = null;
          this.formSubmitting = false;
        })
        .catch(() => {
          this.selectedTrigger = null;
          this.formSubmitting = false;
        });
    },
    submitTrackerTrigger(formData) {
      this.formSubmitting = true;
      this.$store
        .dispatch("trackersStore/submitTrackerTrigger", {
          trackerUUID: formData.tracker,
          trigger: this.selectedTrigger.trigger,
          data: formData.data,
        })
        .then(() => {
          this.$emit("refresh-rider", this.rider.uuid);
          this.selectedTrigger = null;
          this.formSubmitting = false;
        })
        .catch(() => {
          this.selectedTrigger = null;
          this.formSubmitting = false;
        });
    },
  },
};
</script>

<style scoped>
.card {
  text-align: left;
}
</style>
