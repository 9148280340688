import { penceToPoundString } from "@/common-functions.js";

let riderData = {
  allRiderTriggers: {
    toggle_green_leaderboard: {
      formComponent: "trigger-with-volunteer",
      verboseName: "Toggle Green Leaderboard",
      eventName: "Green Leaderboard",
      icon: {
        symbol: "fas fa-leaf",
        color: "#42B983",
      },
      userTrigger: true,
    },
    toggle_media_license: {
      formComponent: "trigger-with-volunteer",
      verboseName: "Toggle Media License",
      eventName: "Media License",
      icon: {
        symbol: "fas fa-leaf",
        color: "#42B983",
      },
      userTrigger: true,
    },
    add_note: {
      formComponent: "requires-further-attention",
      verboseName: "Add Note",
      eventName: "Note",
      icon: {
        symbol: "fas fa-sticky-note",
        color: "#42B983",
      },
      userTrigger: true,
    },
    toggle_attention: {
      formComponent: "trigger-with-volunteer",
      verboseName: "Remove Flag",
      eventName: "Removed flag",
      icon: {
        symbol: "fas fa-flag",
        color: "#42B983",
      },
      userTrigger: true,
    },
    id_check: {
      formComponent: "trigger-with-volunteer",
      verboseName: "ID Check",
      eventName: "ID Check",
      icon: {
        symbol: "fas fa-check",
        color: "#42B983",
      },
      userTrigger: true,
    },
    bike_check_fail: {
      formComponent: "trigger-with-volunteer",
      verboseName: "Bike Check FAIL",
      eventName: "Bike Check FAIL",
      icon: {
        symbol: "fas fa-ban",
        color: "#e75a7c",
      },
      userTrigger: true,
    },
    bike_check_pass: {
      formComponent: "requires-further-attention",
      verboseName: "Bike Check PASS",
      eventName: "Bike Check PASS",
      icon: {
        symbol: "fas fa-check",
        color: "#42B983",
      },
      userTrigger: true,
    },
    doesnt_start: {
      formComponent: "trigger-with-volunteer",
      verboseName: "DNS",
      eventName: "DNS",
      icon: {
        symbol: "fas fa-ban",
        color: "#e75a7c",
      },
      userTrigger: true,
    },
    scratches: {
      formComponent: "trigger-with-volunteer",
      verboseName: "Scratch",
      eventName: "Scratched",
      icon: {
        symbol: "fas fa-ban",
        color: "#e75a7c",
      },
      userTrigger: true,
    },
    fully_registered: {
      verboseName: "Registers",
      eventName: "Registers",
      icon: {
        symbol: "fas fa-flag-checkered",
        color: "#5c95ff",
      },
      userTrigger: false,
    },
    starts: {
      verboseName: "Starts",
      eventName: "Started",
      icon: {
        symbol: "fas fa-flag-checkered",
        color: "#5c95ff",
      },
      userTrigger: false,
    },
    finishes: {
      verboseName: "Finishes",
      eventName: "Finished",
      icon: {
        symbol: "fas fa-flag-checkered",
        color: "#42B983",
      },
      userTrigger: false,
    },
    give_tracker: {
      formComponent: "give-tracker",
      verboseName: "Give tracker",
      eventName: "Received tracker",
      icon: {
        symbol: "fas fa-sign-out-alt",
        color: "#5c95ff",
      },
      userTrigger: true,
    },
    retrieve_tracker: {
      formComponent: "retrieve-tracker",
      verboseName: "Retrieve tracker",
      eventName: "Returned tracker",
      icon: {
        symbol: "fas fa-sign-in-alt",
        color: "#5c95ff",
      },
      userTrigger: true,
    },
    lose_tracker: {
      formComponent: "trigger-with-volunteer",
      verboseName: "Record as lost",
      eventName: "Lost tracker",
      icon: {
        symbol: "fas fa-ban",
        color: "#e75a7c",
      },
      userTrigger: false,
    },
  },
  riderStates: {
    dns: { verboseName: "DNS", color: "secondary" },
    not_active: { verboseName: "Not active", color: "secondary" },
    registering: { verboseName: "Registering", color: "primary" },
    active: { verboseName: "Active", color: "success" },
    scratched: { verboseName: "Scratched", color: "danger" },
    finished: { verboseName: "Finished", color: "success" },
  },
  riderInfo: {
    email: {
      verboseName: "Email",
    },
    balance: {
      verboseName: "Balance",
      displayRepresentation: penceToPoundString,
    },
    country: { verboseName: "Country" },
    wave: {
      verboseName: "Wave",
      displayRepresentation: function (wave) {
        return wave ? wave.name : "";
      },
    },
    gender: {
      verboseName: "Gender",
      displayRepresentation: function (gender) {
        return gender == "F" ? "Female" : "Male";
      },
    },
  },
};

export { riderData };
