import { render, staticRenderFns } from "./CheckBoxField.vue?vue&type=template&id=015b8519&scoped=true&"
import script from "./CheckBoxField.vue?vue&type=script&lang=js&"
export * from "./CheckBoxField.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "015b8519",
  null
  
)

export default component.exports