<template>
  <ApplyFlowLayout :race="selectedRace">
    <template v-slot:body>
      <Loading v-if="loading" />
      <GetApplyRace @race-selected="selectRace" @error="redirect404" />
      <b-card-text v-html="introduction_paragraphs"> </b-card-text>
      <b-form
        @submit.prevent="createApply"
        v-if="Object.keys(selectedRace).length > 0"
      >
        <b-form-group>
          <label for="email-input">{{
            selectedRace.create_fields.email[selectedLanguage]
          }}</label
          ><b-form-input
            id="email-input"
            v-model="email"
            :state="validateRequired(email)"
            required
            help="This is some help text"
            type="email"
          ></b-form-input>
        </b-form-group>
        <b-form-group v-if="selectedRace.available_languages.length > 0">
          <label for="language-input">{{
            selectedRace.create_fields.language[selectedLanguage]
          }}</label>
          <b-form-select
            id="language-input"
            :options="selectedRace.available_languages"
            text-field="label"
            v-model="selectedLanguage"
            :state="validateRequired(selectedLanguage)"
            required
          >
          </b-form-select>
        </b-form-group>
        <b-form-group>
          <label for="category-input">{{
            selectedRace.create_fields.category[selectedLanguage]
          }}</label>
          <b-form-select
            id="category-input"
            :options="selectedRace.available_categories[selectedLanguage]"
            v-model="selectedCategory"
            :state="validateRequired(selectedCategory)"
            required
            text-field="label"
            @change="emptyPairEmail"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group v-if="pairCategories.includes(selectedCategory)">
          <p>{{ selectedRace.pair_create_guidance[selectedLanguage] }}</p>
          <label for="pair_email-input">{{
            selectedRace.create_fields.pair_email[selectedLanguage]
          }}</label>
          <b-form-input
            id="pair_email-input"
            v-model="pairEmail"
            :state="validateRequired(pairEmail)"
            required
            type="email"
          ></b-form-input>
        </b-form-group>
        <b-button @click="formDirty = true" type="submit" class="mt-3">{{
          selectedRace.buttons.submit[selectedLanguage]
        }}</b-button>
      </b-form>
    </template>
  </ApplyFlowLayout>
</template>

<script>
import FormBuilder from "@/components/renderingComponents/FormBuilder.vue";
import Loading from "@/components/renderingComponents/Loading.vue";
import ApplyFlowLayout from "@/components/layoutComponents/ApplyFlowLayout.vue";
import GetApplyRace from "@/components/logicComponents/GetApplyRace.vue";

export default {
  name: "ApplyCreate",
  components: {
    FormBuilder,
    Loading,
    ApplyFlowLayout,
    GetApplyRace,
  },
  data() {
    return {
      loading: true,
      checkEmail: false,
      availableRaces: [],
      selectedRace: Object(),
      selectedLanguage: "english",
      selectedCategory: null,
      email: "",
      pairEmail: "",
      pairCategories: ["pair", "tandem"],
      formDirty: false,
    };
  },
  created() {},
  mounted() {},
  computed: {
    introduction_paragraphs() {
      return this.selectedRace.introduction_paragraphs
        ? this.selectedRace.introduction_paragraphs[this.selectedLanguage]
        : "";
    },
  },
  methods: {
    validateRequired(value) {
      return this.formDirty ? !!value && value.length > 0 : null;
    },
    emptyPairEmail() {
      this.pairEmail = "";
    },
    createApply(formData) {
      this.loading = true;
      let pairData = {};
      if (this.pairEmail) {
        pairData = { pair_email: this.pairEmail };
      }
      this.$store
        .dispatch("applyStore/createApplicantV2", {
          race: this.selectedRace.uuid,
          email: this.email,
          category: this.selectedCategory,
          default_language: this.selectedLanguage,
          ...pairData,
        })
        .then(
          (res) => (
            (this.loading = false),
            this.$router.push({
              name: "applyCheckEmail",
              params: {
                raceShortName: this.selectedRace.short_name,
              },
              query: { language: this.selectedLanguage },
            })
          )
        );
    },
    redirect404() {
      this.$router.push({ name: "notFound" });
    },
    selectRace(race) {
      this.loading = false;
      this.selectedRace = race;
    },
  },
};
</script>
